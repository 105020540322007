import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { extractAlbumAndPrints } from 'bubble-utils/src/album-utils';
import { isBinaryFlagEnabled } from 'bubble-utils/src/code-utils';
import { formatAsFrenchUtcDoubleDigitFromIso } from 'bubble-utils/src/date-utils';

import {
  addAuthorToFollowApi,
  addSerieToFollowApi,
  loadFollowedApi,
  loadLibraryApi,
  loadLibraryAuthorFollowApi,
  loadLibraryPrintsOptionsApi,
  loadLoanedAlbumsApi,
  loadRecommendationApi,
  loadSelectedAlbumPrintsApi,
  loadSignedAlbumsApi,
  loadUserAgendaApi,
  loadUserStatsApi,
  loadWishlistAlbumsApi,
  removeAuthorFromFollowApi,
  removeSerieFromFollowApi,
  updateLibraryApi,
  updateWishlistAlbumsApi,
} from '../services/api';

import { getClientId, getClientToken } from '../selectors';
import { loadAlbum, storeAlbums } from './albums';
import { loadAuthor, storeAuthors } from './authors';
import { storePrints } from './prints';

import { GENERAL } from 'bubble-constants';

export const LOAD_LIBRARY = 'profiles/loadLibrary';
const LOAD_LIBRARY_PRINTS_OPTIONS = 'profiles/loadLibraryPrintsOptions';
export const LOAD_FOLLOWED = 'profiles/loadFollowed';
const LOAD_USER_AGENDA = 'profiles/loadUserAgenda';
const LOAD_USER_STATS = 'profiles/loadUserStats';
const LOAD_SIGNED_ALBUMS = 'profiles/loadSignedAlbums';
const LOAD_LOANED_ALBUMS = 'profiles/loadLoanedAlbums';
const LOAD_WISHLIST_ALBUMS = 'profiles/loadWishlistAlbums';
const LOAD_SELECTED_ALBUM_PRINTS = 'profiles/loadSelectedAlbumPrints';
const LOAD_RECOMMENDATION = 'profiles/loadRecommendation';
const LOAD_LIBRARY_AUTHOR_FOLLOW = 'profiles/loadLibraryAuthorFollow';
const ADD_AUTHOR_TO_FOLLOW = 'profiles/addAuthorToFollow';
const REMOVE_AUTHOR_FROM_FOLLOW = 'profiles/removeAuthorFromFollow';
const ADD_SERIE_TO_FOLLOW = 'profiles/addSerieToFollow';
const REMOVE_SERIE_FROM_FOLLOW = 'profiles/removeSerieFromFollow';
const UPDATE_WISHLIST_PRINTS = 'profiles/updateWishlistPrints';
const UPDATE_LIBRARY = 'profiles/updateLibrary';

export const initialProfile = {
  ownedAlbumsIdsMap: {},
  ownedPrintsIdsMap: {},
  readAlbumsIdsMap: {},
  readPrintsIdsMap: {},
  signedAlbumsIdsMap: {},
  signedPrintsIdsMap: {},
  wishlistAlbumsIdsMap: {},
  wishlistPrintsIdsMap: {},
  originalEditionsAlbumsIdsMap: {},
  originalEditionsPrintsIdsMap: {},
  loanAlbumsIdsMap: {},
  loanPrintsIdsMap: {},
  memoPrintsIdsMap: {},
  deluxePrintsIdsMap: {},
  deluxeAlbumsIdsMap: {},
  digitalPrintsIdsMap: {},
  digitalAlbumsIdsMap: {},
  exlibrisPrintsIdsMap: {},
  exlibrisAlbumsIdsMap: {},
  limitedPrintsIdsMap: {},
  limitedAlbumsIdsMap: {},
  forSalePrintsIdsMap: {},
  forSaleAlbumsIdsMap: {},
  buyPricePrintsIdsMap: {},
  resellValuePrintsIdsMap: {},
  conditionPrintsIdsMap: {},
  numberingPrintsIdsMap: {},
  recommendedAlbumsIdsMap: {},
  followedSeriesIdsMap: {},
  unfollowedSeriesIdsMap: {},
  followedAuthorsIdsMap: {},
  selectedAlbumPrintObjectIdsMap: {},
  selectedAlbumPrintsObjectIdsMap: {},
  formattedReceivedLibrary: [],
  formattedReceivedFollowedLibrary: [],
  mySeries: [],
  myAlbums: {},
  myAgenda: [],
  stats: {},
  loanAlbums: [],
  myCompletionSeries: [],
  temporaryAddedToLibraryAlbumsMap: {},
  temporaryRemovedFromLibraryAlbumsMap: {},
  temporaryAddedToLibraryPrintsMap: {},
  temporaryRemovedFromLibraryPrintsMap: {},
};

const initialState = {
  otherProfiles: {},
  ...initialProfile,
  loading: {},
  errors: {},
};

export const loadLibrary = createAsyncThunk(LOAD_LIBRARY, async (params, { getState }) => {
  const libraryObjectId = params?.libraryObjectId || null;
  const isGuest = params?.isGuest || false;

  const clientToken = getClientToken(getState());
  const library = await loadLibraryApi(clientToken, libraryObjectId);
  return { isGuest, libraryObjectId, library };
});

export const loadLibraryPrintsOptions = createAsyncThunk(
  LOAD_LIBRARY_PRINTS_OPTIONS,
  async (params, { getState }) => {
    const libraryObjectId = params?.libraryObjectId || null;
    const isGuest = params?.isGuest || false;

    const clientToken = getClientToken(getState());
    const libraryPrintsOptions = await loadLibraryPrintsOptionsApi(clientToken, libraryObjectId);
    return { libraryObjectId, libraryPrintsOptions, isGuest };
  },
);

export const loadFollowed = createAsyncThunk(LOAD_FOLLOWED, async (params, { getState }) => {
  const libraryObjectId = params?.libraryObjectId || null;
  const isGuest = params?.isGuest || false;

  const clientToken = getClientToken(getState());
  const followed = await loadFollowedApi(clientToken, libraryObjectId);

  return { libraryObjectId, followed, isGuest };
});

export const loadUserAgenda = createAsyncThunk(
  LOAD_USER_AGENDA,
  async (params, { getState, dispatch }) => {
    const libraryObjectId = params?.libraryObjectId || null;
    const months = params?.months || null;
    const isGuest = params?.isGuest || false;

    const clientToken = getClientToken(getState());
    const agenda = await loadUserAgendaApi(clientToken, libraryObjectId, months);
    // hack to have the correct print id publication date within the album in the agenda.
    const myAgenda = agenda.map((album) => ({
      printObjectId: album.prints[0].objectId,
      albumObjectId: album.objectId,
      publicationDate: album.prints[0].publicationDate,
      userAgendaExtras: album.userAgendaExtras,
    }));

    const { albums, prints } = extractAlbumAndPrints(agenda);
    dispatch(storeAlbums({ albums }));
    dispatch(storePrints({ prints }));

    return { libraryObjectId, myAgenda, isGuest, months };
  },
);

export const loadUserStats = createAsyncThunk(
  LOAD_USER_STATS,
  async (params, { getState, dispatch }) => {
    const libraryObjectId = params?.libraryObjectId || null;
    const isGuest = params?.isGuest || false;

    const clientToken = getClientToken(getState());
    const stats = await loadUserStatsApi(clientToken, libraryObjectId);
    return { libraryObjectId, stats, isGuest };
  },
);

export const loadSignedAlbums = createAsyncThunk(
  LOAD_SIGNED_ALBUMS,
  async (params, { getState, dispatch }) => {
    const libraryObjectId = params?.libraryObjectId || null;
    const isGuest = params?.isGuest || false;

    const clientToken = getClientToken(getState());
    const albums = await loadSignedAlbumsApi(clientToken, libraryObjectId);
    return { libraryObjectId, albums, isGuest };
  },
);

export const loadLoanedAlbums = createAsyncThunk(
  LOAD_LOANED_ALBUMS,
  async (params, { getState, dispatch }) => {
    const libraryObjectId = params?.libraryObjectId || null;
    const isGuest = params?.isGuest || false;

    const clientToken = getClientToken(getState());
    const loanedAlbums = await loadLoanedAlbumsApi(clientToken, libraryObjectId);
    return { libraryObjectId, loanedAlbums, isGuest };
  },
);

export const loadWishlistAlbums = createAsyncThunk(
  LOAD_WISHLIST_ALBUMS,
  async (params, { getState, dispatch }) => {
    const libraryObjectId = params?.libraryObjectId || null;
    const isGuest = params?.isGuest || false;

    const clientToken = getClientToken(getState());
    const wishlistAlbums = await loadWishlistAlbumsApi(clientToken, libraryObjectId);

    const { albums, prints } = extractAlbumAndPrints(wishlistAlbums);
    dispatch(storeAlbums({ albums }));
    dispatch(storePrints({ prints }));
    return { libraryObjectId, wishlistAlbums, isGuest };
  },
);

export const loadSelectedAlbumPrints = createAsyncThunk(
  LOAD_SELECTED_ALBUM_PRINTS,
  async (params, { getState, dispatch }) => {
    const libraryObjectId = params?.libraryObjectId || null;

    const clientToken = getClientToken(getState());
    const selectedPrints = await loadSelectedAlbumPrintsApi(clientToken, libraryObjectId);
    dispatch(storePrints({ prints: selectedPrints }));
    return { selectedPrints };
  },
);

export const loadRecommendation = createAsyncThunk(
  LOAD_RECOMMENDATION,
  async (params, { getState, dispatch }) => {
    const libraryObjectId = params?.libraryObjectId || null;

    const clientToken = getClientToken(getState());
    const recommandedAlbums = await loadRecommendationApi(clientToken, libraryObjectId);

    const { albums, prints } = extractAlbumAndPrints(recommandedAlbums);
    dispatch(storeAlbums({ albums }));
    dispatch(storePrints({ prints }));
    return { albums: recommandedAlbums };
  },
);

export const loadLibraryAuthorFollow = createAsyncThunk(
  LOAD_LIBRARY_AUTHOR_FOLLOW,
  async (params, { getState, dispatch }) => {
    const userObjectId = params?.userObjectId || null;

    const clientToken = getClientToken(getState());
    const authors = await loadLibraryAuthorFollowApi(clientToken, userObjectId);
    dispatch(storeAuthors({ authors }));
    return { authors };
  },
);

export const addAuthorToFollow = createAsyncThunk(
  ADD_AUTHOR_TO_FOLLOW,
  async (params, { getState, dispatch }) => {
    const userObjectId = params?.userObjectId || null;
    const authorObjectId = params?.authorObjectId || null;

    const clientToken = getClientToken(getState());
    await addAuthorToFollowApi(clientToken, userObjectId, authorObjectId);
    dispatch(loadLibraryAuthorFollow({ userObjectId }));
    dispatch(loadUserAgenda({ libraryObjectId: userObjectId }));
  },
);

export const removeAuthorFromFollow = createAsyncThunk(
  REMOVE_AUTHOR_FROM_FOLLOW,
  async (params, { getState, dispatch }) => {
    const userObjectId = params?.userObjectId || null;
    const authorObjectId = params?.authorObjectId || null;

    const clientToken = getClientToken(getState());
    await removeAuthorFromFollowApi(clientToken, userObjectId, authorObjectId);
    dispatch(loadAuthor({ authorObjectId })), dispatch(loadLibraryAuthorFollow({ userObjectId }));
    dispatch(loadUserAgenda({ libraryObjectId: userObjectId }));
  },
);

export const addSerieToFollow = createAsyncThunk(
  ADD_SERIE_TO_FOLLOW,
  async (params, { getState, dispatch }) => {
    const libraryObjectId = params?.libraryObjectId || null;
    const serieObjectId = params?.serieObjectId || null;

    const clientToken = getClientToken(getState());
    await addSerieToFollowApi(clientToken, libraryObjectId, serieObjectId);
    dispatch(loadFollowed({ libraryObjectId }));
    dispatch(loadUserAgenda({ libraryObjectId }));
  },
);

export const removeSerieFromFollow = createAsyncThunk(
  REMOVE_SERIE_FROM_FOLLOW,
  async (params, { getState, dispatch }) => {
    const libraryObjectId = params?.libraryObjectId || null;
    const serieObjectId = params?.serieObjectId || null;

    const clientToken = getClientToken(getState());
    await removeSerieFromFollowApi(clientToken, libraryObjectId, serieObjectId);
    dispatch(loadFollowed({ libraryObjectId }));
    dispatch(loadUserAgenda({ libraryObjectId }));
  },
);

export const updateWishlistPrints = createAsyncThunk(
  UPDATE_WISHLIST_PRINTS,
  async (params, { getState, dispatch }) => {
    const libraryObjectId = params?.libraryObjectId || null;
    const printIds = params?.printIds || null;
    const wishlistObject = params?.wishlistObject || null;

    const clientToken = getClientToken(getState());
    await updateWishlistAlbumsApi(clientToken, libraryObjectId, printIds, wishlistObject);
    dispatch(loadWishlistAlbums({ libraryObjectId, isGuest: true }));
    return { libraryObjectId };
  },
);

export const updateLibrary = createAsyncThunk(
  UPDATE_LIBRARY,
  async (params, { getState, dispatch }) => {
    const userObjectId = params?.userObjectId || getClientId(getState()) || null;
    const prints = params?.prints || null;

    const clientToken = getClientToken(getState());
    await updateLibraryApi(clientToken, userObjectId, prints);

    if (prints.some((print) => print.isWishlist !== undefined)) {
      dispatch(loadWishlistAlbums({ libraryObjectId: userObjectId }));
      const albumObjectIdsMap = {};
      prints.forEach((print) => {
        if (print?.albumObjectId) albumObjectIdsMap[print.albumObjectId] = true;
      });

      Object.keys(albumObjectIdsMap).map((albumObjectId) => dispatch(loadAlbum({ albumObjectId })));
    }

    loadLibraryDelayed(userObjectId, dispatch);

    return { userObjectId };
  },
);

const loadLibraryDelayed = (libraryObjectId, dispatch) => {
  setTimeout(() => {
    dispatch(loadUserAgenda({ libraryObjectId }));
    dispatch(loadLibrary({ libraryObjectId }));
    dispatch(loadLibraryPrintsOptions({ libraryObjectId }));
    dispatch(loadUserStats({ libraryObjectId }));
  }, 3000);
};

const profilesSlice = createSlice({
  name: 'profiles',
  initialState,
  reducers: {
    resetProfilesReducer: {
      reducer: (state, action) => initialState,
    },
    setEoFlagOnPrints: {
      reducer: (state, action) => {
        const { userObjectId, prints, isBatch } = action.payload;

        const originalEditionsAlbumsIdsMap = { ...state.originalEditionsAlbumsIdsMap };
        const originalEditionsPrintsIdsMap = { ...state.originalEditionsPrintsIdsMap };
        prints.forEach((print) => {
          // For collection batch actions
          if (isBatch || prints.length > 1 || print.isOriginalEdition === true) {
            originalEditionsAlbumsIdsMap[print.albumObjectId] = print.isOriginalEdition;
          }
          originalEditionsPrintsIdsMap[print.printObjectId] = print.isOriginalEdition;
        });
        state.originalEditionsAlbumsIdsMap = originalEditionsAlbumsIdsMap;
        state.originalEditionsPrintsIdsMap = originalEditionsPrintsIdsMap;
      },
    },
    setReadFlagOnPrints: {
      reducer: (state, action) => {
        const { userObjectId, prints, isBatch } = action.payload;

        let readAlbumsIdsMap = { ...state.readAlbumsIdsMap };
        let readPrintsIdsMap = { ...state.readPrintsIdsMap };
        prints.forEach((print) => {
          if (isBatch || prints.length > 1 || print.isRead === true) {
            readAlbumsIdsMap[print.albumObjectId] = print.isRead;
          }
          if (print.isRead === true) {
            readPrintsIdsMap[print.printObjectId] = print.isRead;
          } else {
            delete readPrintsIdsMap[print.printObjectId];
          }
        });

        let mySeries = [...state.mySeries];

        // update mySeries so that "Mes bd visu album" acts properly on unreading
        if (isBatch) {
          mySeries = deepCloneLibraryArray(state.formattedReceivedLibrary).map((serie) => {
            // keeping only album read or in library
            serie.albums = serie.albums.filter(
              (albumObjectId) =>
                readAlbumsIdsMap[albumObjectId] ||
                (state.ownedAlbumsIdsMap[albumObjectId] &&
                  !state.temporaryRemovedFromLibraryAlbumsMap[albumObjectId]),
            );
            return serie;
          });
        }

        state.mySeries = mySeries;
        state.readAlbumsIdsMap = readAlbumsIdsMap;
        state.readPrintsIdsMap = readPrintsIdsMap;
      },
    },
    setSignedFlagOnPrints: {
      reducer: (state, action) => {
        const { userObjectId, prints, isBatch } = action.payload;

        const signedAlbumsIdsMap = { ...state.signedAlbumsIdsMap };
        const signedPrintsIdsMap = { ...state.signedPrintsIdsMap };
        prints.forEach((print) => {
          if (isBatch || prints.length > 1 || print.isSigned === true) {
            signedAlbumsIdsMap[print.albumObjectId] = print.isSigned;
          }
          signedPrintsIdsMap[print.printObjectId] = print.isSigned;
        });

        state.signedAlbumsIdsMap = signedAlbumsIdsMap;
        state.signedPrintsIdsMap = signedPrintsIdsMap;
      },
    },
    setDeluxeFlagOnPrints: {
      reducer: (state, action) => {
        const { userObjectId, prints, isBatch } = action.payload;

        const deluxeAlbumsIdsMap = { ...state.deluxeAlbumsIdsMap };
        const deluxePrintsIdsMap = { ...state.deluxePrintsIdsMap };
        prints.forEach((print) => {
          if (isBatch || prints.length > 1 || print.isDeluxe) {
            deluxeAlbumsIdsMap[print.albumObjectId] = print.isDeluxe;
          }
          deluxePrintsIdsMap[print.printObjectId] = print.isDeluxe;
        });

        state.deluxeAlbumsIdsMap = deluxeAlbumsIdsMap;
        state.deluxePrintsIdsMap = deluxePrintsIdsMap;
      },
    },
    setLimitedFlagOnPrints: {
      reducer: (state, action) => {
        const { userObjectId, prints, isBatch } = action.payload;

        const limitedAlbumsIdsMap = { ...state.limitedAlbumsIdsMap };
        const limitedPrintsIdsMap = { ...state.limitedPrintsIdsMap };
        prints.forEach((print) => {
          if (isBatch || prints.length > 1 || print.isLimited) {
            limitedAlbumsIdsMap[print.albumObjectId] = print.isLimited;
          }
          limitedPrintsIdsMap[print.printObjectId] = print.isLimited;
        });

        state.limitedAlbumsIdsMap = limitedAlbumsIdsMap;
        state.limitedPrintsIdsMap = limitedPrintsIdsMap;
      },
    },
    setExlibrisFlagOnPrints: {
      reducer: (state, action) => {
        const { userObjectId, prints, isBatch } = action.payload;

        const exlibrisAlbumsIdsMap = { ...state.exlibrisAlbumsIdsMap };
        const exlibrisPrintsIdsMap = { ...state.exlibrisPrintsIdsMap };
        prints.forEach((print) => {
          if (isBatch || prints.length > 1 || print.isWithExLibris) {
            exlibrisAlbumsIdsMap[print.albumObjectId] = print.isWithExLibris;
          }
          exlibrisPrintsIdsMap[print.printObjectId] = print.isWithExLibris;
        });

        state.exlibrisAlbumsIdsMap = exlibrisAlbumsIdsMap;
        state.exlibrisPrintsIdsMap = exlibrisPrintsIdsMap;
      },
    },
    setDigitalFlagOnPrints: {
      reducer: (state, action) => {
        const { userObjectId, prints, isBatch } = action.payload;

        const digitalAlbumsIdsMap = { ...state.digitalAlbumsIdsMap };
        const digitalPrintsIdsMap = { ...state.digitalPrintsIdsMap };
        prints.forEach((print) => {
          if (isBatch || prints.length > 1 || print.isDigital) {
            digitalAlbumsIdsMap[print.albumObjectId] = print.isDigital;
          }
          digitalPrintsIdsMap[print.printObjectId] = print.isDigital;
        });

        state.digitalAlbumsIdsMap = digitalAlbumsIdsMap;
        state.digitalPrintsIdsMap = digitalPrintsIdsMap;
      },
    },
    setForSaleFlagOnPrints: {
      reducer: (state, action) => {
        const { userObjectId, prints, isBatch } = action.payload;

        const forSaleAlbumsIdsMap = { ...state.forSaleAlbumsIdsMap };
        const forSalePrintsIdsMap = { ...state.forSalePrintsIdsMap };
        prints.forEach((print) => {
          if (isBatch || prints.length > 1 || print.isForSale) {
            forSaleAlbumsIdsMap[print.albumObjectId] = print.isForSale;
          }
          forSalePrintsIdsMap[print.printObjectId] = print.isForSale;
        });

        state.forSaleAlbumsIdsMap = forSaleAlbumsIdsMap;
        state.forSalePrintsIdsMap = forSalePrintsIdsMap;
      },
    },
    setNumberingFlagOnPrints: {
      reducer: (state, action) => {
        const { userObjectId, prints, isBatch } = action.payload;

        const numberingAlbumsIdsMap = { ...state.numberingAlbumsIdsMap };
        const numberingPrintsIdsMap = { ...state.numberingPrintsIdsMap };
        prints.forEach((print) => {
          if (isBatch || prints.length > 1 || print.numbering) {
            numberingAlbumsIdsMap[print.printObjectId] = print.numbering;
          }
          numberingPrintsIdsMap[print.printObjectId] = print.numbering;
        });

        state.numberingAlbumsIdsMap = numberingAlbumsIdsMap;
        state.numberingPrintsIdsMap = numberingPrintsIdsMap;
      },
    },
    setBuyPriceFlagOnPrints: {
      reducer: (state, action) => {
        const { userObjectId, prints, isBatch } = action.payload;

        const buyPriceAlbumsIdsMap = { ...state.buyPriceAlbumsIdsMap };
        const buyPricePrintsIdsMap = { ...state.buyPricePrintsIdsMap };
        prints.forEach((print) => {
          if (isBatch || prints.length > 1 || print.buyPrice) {
            buyPriceAlbumsIdsMap[print.printObjectId] = print.buyPrice;
          }
          buyPricePrintsIdsMap[print.printObjectId] = print.buyPrice;
        });
        state.buyPriceAlbumsIdsMap = buyPriceAlbumsIdsMap;
        state.buyPricePrintsIdsMap = buyPricePrintsIdsMap;
      },
    },
    setResellValueFlagOnPrints: {
      reducer: (state, action) => {
        const { userObjectId, prints, isBatch } = action.payload;

        const resellValueAlbumsIdsMap = { ...state.resellValueAlbumsIdsMap };
        const resellValuePrintsIdsMap = { ...state.resellValuePrintsIdsMap };
        prints.forEach((print) => {
          if (isBatch || prints.length > 1 || print.resellValue) {
            resellValueAlbumsIdsMap[print.printObjectId] = print.resellValue;
          }
          resellValuePrintsIdsMap[print.printObjectId] = print.resellValue;
        });
        state.resellValueAlbumsIdsMap = resellValueAlbumsIdsMap;
        state.resellValuePrintsIdsMap = resellValuePrintsIdsMap;
      },
    },
    setConditionFlagOnPrints: {
      reducer: (state, action) => {
        const { userObjectId, prints, isBatch } = action.payload;

        const conditionAlbumsIdsMap = { ...state.conditionAlbumsIdsMap };
        const conditionPrintsIdsMap = { ...state.conditionPrintsIdsMap };
        prints.forEach((print) => {
          if (isBatch || prints.length > 1 || print.condition) {
            conditionAlbumsIdsMap[print.printObjectId] = print.condition;
          }
          conditionPrintsIdsMap[print.printObjectId] = print.condition;
        });
        state.conditionAlbumsIdsMap = conditionAlbumsIdsMap;
        state.conditionPrintsIdsMap = conditionPrintsIdsMap;
      },
    },
    updateMemoPrints: {
      reducer: (state, action) => {
        const { userObjectId, prints, isBatch } = action.payload;

        const memoPrintsIdsMap = { ...state.memoPrintsIdsMap };
        prints.forEach((print) => {
          memoPrintsIdsMap[print.printObjectId] = print.memo;
        });
        state.memoPrintsIdsMap = memoPrintsIdsMap;
      },
    },
    addPrintsToLibrary: {
      reducer: (state, action) => {
        const { userObjectId, prints, isBatch } = action.payload;

        const selectedAlbumPrintsObjectIdsMap = { ...state.selectedAlbumPrintsObjectIdsMap };
        const ownedAlbumsIdsMap = { ...state.ownedAlbumsIdsMap };
        const ownedPrintsIdsMap = { ...state.ownedPrintsIdsMap };
        const temporaryAddedToLibraryAlbumsMap = { ...state.temporaryAddedToLibraryAlbumsMap };
        const temporaryRemovedFromLibraryAlbumsMap = {
          ...state.temporaryRemovedFromLibraryAlbumsMap,
        };
        const temporaryAddedToLibraryPrintsMap = { ...state.temporaryAddedToLibraryPrintsMap };
        const temporaryRemovedFromLibraryPrintsMap = {
          ...state.temporaryRemovedFromLibraryPrintsMap,
        };
        prints.forEach((print) => {
          ownedAlbumsIdsMap[print.albumObjectId] = (ownedAlbumsIdsMap[print.albumObjectId] || [])
            .concat([print.printObjectId])
            .filter((item, pos, self) => self.indexOf(item) == pos)
            .sort((a, b) => a > b);
          temporaryAddedToLibraryAlbumsMap[print.albumObjectId] = (
            temporaryAddedToLibraryAlbumsMap[print.albumObjectId] || []
          )
            .concat([print.printObjectId])
            .filter((item, pos, self) => self.indexOf(item) == pos)
            .sort((a, b) => a > b);
          ownedPrintsIdsMap[print.printObjectId] = true;
          temporaryAddedToLibraryPrintsMap[print.printObjectId] = true;

          delete temporaryRemovedFromLibraryPrintsMap[print.printObjectId];
          delete temporaryRemovedFromLibraryAlbumsMap[print.albumObjectId];
        });

        const arrays = updateArrays(
          state,
          temporaryAddedToLibraryAlbumsMap,
          temporaryRemovedFromLibraryAlbumsMap,
        );

        state.ownedAlbumsIdsMap = ownedAlbumsIdsMap;
        state.ownedPrintsIdsMap = ownedPrintsIdsMap;
        state.selectedAlbumPrintsObjectIdsMap = selectedAlbumPrintsObjectIdsMap;
        state.temporaryAddedToLibraryAlbumsMap = temporaryAddedToLibraryAlbumsMap;
        state.temporaryRemovedFromLibraryAlbumsMap = temporaryRemovedFromLibraryAlbumsMap;
        state.temporaryAddedToLibraryPrintsMap = temporaryAddedToLibraryPrintsMap;
        state.temporaryRemovedFromLibraryPrintsMap = temporaryRemovedFromLibraryPrintsMap;
        state.myCompletionSeries = arrays[0];
        state.mySeries = arrays[1];
      },
    },
    removePrintsFromLibrary: {
      reducer: (state, action) => {
        const { userObjectId, prints, isBatch } = action.payload;

        const ownedAlbumsIdsMap = { ...state.ownedAlbumsIdsMap };
        const ownedPrintsIdsMap = { ...state.ownedPrintsIdsMap };

        const temporaryAddedToLibraryAlbumsMap = { ...state.temporaryAddedToLibraryAlbumsMap };
        const temporaryRemovedFromLibraryAlbumsMap = {
          ...state.temporaryRemovedFromLibraryAlbumsMap,
        };
        const temporaryAddedToLibraryPrintsMap = { ...state.temporaryAddedToLibraryPrintsMap };
        const temporaryRemovedFromLibraryPrintsMap = {
          ...state.temporaryRemovedFromLibraryPrintsMap,
        };

        prints.forEach((print) => {
          // For collection batch actions
          if (isBatch) {
            delete temporaryAddedToLibraryAlbumsMap[print.albumObjectId];
            temporaryRemovedFromLibraryAlbumsMap[print.albumObjectId] = true;
          }
          delete ownedPrintsIdsMap[print.printObjectId];
          delete temporaryAddedToLibraryPrintsMap[print.printObjectId];
          temporaryRemovedFromLibraryPrintsMap[print.printObjectId] = true;
          ownedAlbumsIdsMap[print.albumObjectId] = (
            ownedAlbumsIdsMap[print.albumObjectId] || []
          ).filter((printObjectId) => printObjectId !== print.printObjectId);
          if (temporaryAddedToLibraryAlbumsMap[print.albumObjectId]) {
            temporaryAddedToLibraryAlbumsMap[print.albumObjectId] = (
              temporaryAddedToLibraryAlbumsMap[print.albumObjectId] || []
            ).filter((printObjectId) => printObjectId !== print.printObjectId);
          }
          if (!(ownedAlbumsIdsMap[print.albumObjectId] || []).length) {
            delete ownedAlbumsIdsMap[print.albumObjectId];
            temporaryRemovedFromLibraryAlbumsMap[print.albumObjectId] = true;
          }
          if (!(temporaryAddedToLibraryAlbumsMap[print.albumObjectId] || []).length) {
            delete temporaryAddedToLibraryAlbumsMap[print.albumObjectId];
          }
        });

        //TODO: clean selectedAlbumPrintsObjectIdsMap, that are supposed to be obsolete
        const selectedAlbumPrintsObjectIdsMap = { ...state.selectedAlbumPrintsObjectIdsMap };
        prints.forEach((print) => {
          selectedAlbumPrintsObjectIdsMap[print.albumObjectId] = (
            selectedAlbumPrintsObjectIdsMap[print.albumObjectId] || []
          ).filter((el) => el !== print.printObjectId);
        });

        const arrays = updateArrays(
          state,
          temporaryAddedToLibraryAlbumsMap,
          temporaryRemovedFromLibraryAlbumsMap,
        );

        state.ownedAlbumsIdsMap = ownedAlbumsIdsMap;
        state.ownedPrintsIdsMap = ownedPrintsIdsMap;
        state.selectedAlbumPrintsObjectIdsMap = selectedAlbumPrintsObjectIdsMap;
        state.temporaryAddedToLibraryAlbumsMap = temporaryAddedToLibraryAlbumsMap;
        state.temporaryRemovedFromLibraryAlbumsMap = temporaryRemovedFromLibraryAlbumsMap;
        state.temporaryAddedToLibraryPrintsMap = temporaryAddedToLibraryPrintsMap;
        state.temporaryRemovedFromLibraryPrintsMap = temporaryRemovedFromLibraryPrintsMap;
        state.myCompletionSeries = arrays[0];
        state.mySeries = arrays[1];
      },
    },
    addPrintsToLoan: {
      reducer: (state, action) => {
        const { userObjectId, prints, isBatch } = action.payload;

        const loanAlbumsIdsMap = { ...state.loanAlbumsIdsMap };
        const loanPrintsIdsMap = { ...state.loanPrintsIdsMap };

        prints.forEach((print) => {
          loanAlbumsIdsMap[print.albumObjectId] = print.loan;
          loanPrintsIdsMap[print.printObjectId] = print.loan;
        });

        state.loanAlbumsIdsMap = loanAlbumsIdsMap;
        state.loanPrintsIdsMap = loanPrintsIdsMap;
      },
    },
    removePrintsFromLoan: {
      reducer: (state, action) => {
        const { userObjectId, prints, isBatch } = action.payload;

        const loanAlbumsIdsMap = { ...state.loanAlbumsIdsMap };
        const loanPrintsIdsMap = { ...state.loanPrintsIdsMap };

        prints.forEach((print) => {
          // For collection batch actions
          if (isBatch || prints.length) delete loanAlbumsIdsMap[print.albumObjectId];
          delete loanPrintsIdsMap[print.printObjectId];
        });

        state.loanAlbumsIdsMap = loanAlbumsIdsMap;
        state.loanPrintsIdsMap = loanPrintsIdsMap;
      },
    },
    addPrintsToWishlist: {
      reducer: (state, action) => {
        const { userObjectId, prints, isBatch } = action.payload;

        const wishlistAlbumsIdsMap = { ...state.wishlistAlbumsIdsMap };
        const wishlistPrintsIdsMap = { ...state.wishlistPrintsIdsMap };

        prints.forEach((print) => {
          wishlistAlbumsIdsMap[print.albumObjectId] = print.isWishlist;
          wishlistPrintsIdsMap[print.printObjectId] = print.isWishlist;
        });

        state.wishlistAlbumsIdsMap = wishlistAlbumsIdsMap;
        state.wishlistPrintsIdsMap = wishlistPrintsIdsMap;
      },
    },
    removePrintsFromWishlist: {
      reducer: (state, action) => {
        const { userObjectId, prints, isBatch } = action.payload;

        const wishlistAlbumsIdsMap = { ...state.wishlistAlbumsIdsMap };
        const wishlistPrintsIdsMap = { ...state.wishlistPrintsIdsMap };

        prints.forEach((print) => {
          // For collection batch actions
          if (prints.length > 1) delete wishlistAlbumsIdsMap[print.albumObjectId];
          delete wishlistPrintsIdsMap[print.objectId];
        });

        state.wishlistAlbumsIdsMap = wishlistAlbumsIdsMap;
        state.wishlistPrintsIdsMap = wishlistPrintsIdsMap;
      },
    },
  },
  extraReducers: (builder) => {
    builder
      // loadLibrary
      .addCase(loadLibrary.pending, (state, action) => {
        state.loading[LOAD_LIBRARY] = true;
        state.errors[LOAD_LIBRARY] = null;
      })
      .addCase(loadLibrary.fulfilled, (state, action) => {
        const { isGuest, libraryObjectId, library } = action.payload;

        const rootProfile = isGuest
          ? state.otherProfiles[libraryObjectId] || { ...initialProfile }
          : state;

        const unfollowedSeriesIdsMap = {};
        const selectedAlbumPrintObjectIdsMap = { ...state.selectedAlbumPrintObjectIdsMap };
        const selectedAlbumPrintsObjectIdsMap = { ...state.selectedAlbumPrintsObjectIdsMap };
        const followedSeriesIdsMap = { ...rootProfile.followedSeriesIdsMap };
        const myAlbums = { ...rootProfile.myAlbums };

        // only used to filter serie.albums, not stored afterward
        const ownedAlbumsIdsMap = { ...state.temporaryAddedToLibraryAlbumsMap };
        const readAlbumsIdsMap = {};
        const ownedPrintsIdsMap = { ...state.temporaryAddedToLibraryPrintsMap };
        const readPrintsIdsMap = {};

        // immediately populate albums map
        library?.forEach((serie) => {
          if (serie.unfollowed) {
            unfollowedSeriesIdsMap[serie.serie.objectId] = true;
            delete followedSeriesIdsMap[serie.serie.objectId];
          } else {
            followedSeriesIdsMap[serie.serie.objectId] = true;
          }
          serie.albums?.forEach((album) => {
            Object.entries(album.userPrints).forEach(([printObjectId, flag]) => {
              if (!state.temporaryRemovedFromLibraryPrintsMap[printObjectId]) {
                if (isBinaryFlagEnabled(flag, GENERAL.USER_PRINT.OWNED)) {
                  ownedAlbumsIdsMap[album.objectId] = true;
                  ownedPrintsIdsMap[printObjectId] = true;
                }
                if (isBinaryFlagEnabled(flag, GENERAL.USER_PRINT.READ)) {
                  readAlbumsIdsMap[album.objectId] = true;
                  readPrintsIdsMap[printObjectId] = true;
                }
              }
            });
            myAlbums[album.objectId] = { ...myAlbums[album.objectId], ...formatAlbum(album) };
            if (album.selectedPrintObjectId) {
              selectedAlbumPrintObjectIdsMap[album.objectId] = album.selectedPrintObjectId;
            }
          });
        });

        // create the formattedReceivedLibrary
        const formattedReceivedLibrary = formatAndUpdateReceivedLibraryArray(library);

        let mySeries = deepCloneLibraryArray(formattedReceivedLibrary).map((serie) => {
          // keep only album read or in library
          serie.albums = serie.albums.filter(
            (albumObjectId) =>
              !!ownedAlbumsIdsMap[albumObjectId] || !!readAlbumsIdsMap[albumObjectId],
          );
          return serie;
        });

        const myCompletionSeries = createCompletionData(
          deepCloneLibraryArray(formattedReceivedLibrary),
          rootProfile.formattedReceivedFollowedLibrary,
          unfollowedSeriesIdsMap,
        ).map((serie) => {
          // keep only album not in library, either read or not
          serie.albums = serie.albums.filter((albumObjectId) => !ownedAlbumsIdsMap[albumObjectId]);
          return serie;
        });

        if (!isGuest) {
          state.followedSeriesIdsMap = followedSeriesIdsMap;
          state.unfollowedSeriesIdsMap = unfollowedSeriesIdsMap;
          state.formattedReceivedLibrary = formattedReceivedLibrary;
          state.selectedAlbumPrintObjectIdsMap = selectedAlbumPrintObjectIdsMap;
          state.selectedAlbumPrintsObjectIdsMap = selectedAlbumPrintsObjectIdsMap;
          state.mySeries = mySeries;
          state.myAlbums = myAlbums;
          state.myCompletionSeries = myCompletionSeries;
          state.temporaryAddedToLibraryAlbumsMap = {};
          state.temporaryRemovedFromLibraryAlbumsMap = {};
          state.temporaryAddedToLibraryPrintsMap = {};
          state.temporaryRemovedFromLibraryPrintsMap = {};
        } else {
          const otherProfiles = state.otherProfiles;
          otherProfiles[action.payload.libraryObjectId] =
            otherProfiles[action.payload.libraryObjectId] || rootProfile;
          const otherProfile = otherProfiles[action.payload.libraryObjectId];
          otherProfile.unfollowedSeriesIdsMap = unfollowedSeriesIdsMap;
          otherProfile.followedSeriesIdsMap = followedSeriesIdsMap;
          otherProfile.formattedReceivedLibrary = formattedReceivedLibrary;
          otherProfile.mySeries = mySeries;
          otherProfile.myAlbums = myAlbums;
          otherProfile.myCompletionSeries = myCompletionSeries;

          state.otherProfiles = otherProfiles;
        }

        state.loading[LOAD_LIBRARY] = false;
        state.errors[LOAD_LIBRARY] = null;
      })
      .addCase(loadLibrary.rejected, (state, action) => {
        state.loading[LOAD_LIBRARY] = false;
        state.errors[LOAD_LIBRARY] = action.error;
      })
      // loadLibraryPrintsOptions
      .addCase(loadLibraryPrintsOptions.pending, (state, action) => {
        state.loading[LOAD_LIBRARY_PRINTS_OPTIONS] = true;
        state.errors[LOAD_LIBRARY_PRINTS_OPTIONS] = null;
      })
      .addCase(loadLibraryPrintsOptions.fulfilled, (state, action) => {
        const { isGuest, libraryObjectId, libraryPrintsOptions } = action.payload;

        const rootProfile = isGuest
          ? state.otherProfiles[libraryObjectId] || Object.assign({}, initialProfile)
          : {};
        const maps = {};
        maps.ownedAlbumsIdsMap = rootProfile.ownedAlbumsIdsMap || {};
        maps.ownedPrintsIdsMap = rootProfile.ownedPrintsIdsMap || {};
        maps.readAlbumsIdsMap = rootProfile.readAlbumsIdsMap || {};
        maps.readPrintsIdsMap = rootProfile.readPrintsIdsMap || {};
        maps.wishlistPrintsIdsMap = rootProfile.wishlistPrintsIdsMap || {};
        maps.wishlistAlbumsIdsMap = rootProfile.wishlistAlbumsIdsMap || {};
        maps.originalEditionsAlbumsIdsMap = rootProfile.originalEditionsAlbumsIdsMap || {};
        maps.originalEditionsPrintsIdsMap = rootProfile.originalEditionsPrintsIdsMap || {};
        maps.loanAlbumsIdsMap = rootProfile.loanAlbumsIdsMap || {};
        maps.loanPrintsIdsMap = rootProfile.loanPrintsIdsMap || {};
        maps.signedPrintsIdsMap = rootProfile.signedPrintsIdsMap || {};
        maps.signedAlbumsIdsMap = rootProfile.signedAlbumsIdsMap || {};
        maps.memoPrintsIdsMap = rootProfile.memoPrintsIdsMap || {};
        maps.temporaryAddedToLibraryPrintsMap = rootProfile.temporaryAddedToLibraryPrintsMap || {};
        maps.temporaryRemovedFromLibraryPrintsMap =
          rootProfile.temporaryRemovedFromLibraryPrintsMap || {};
        maps.deluxePrintsIdsMap = rootProfile.deluxePrintsIdsMap || {};
        maps.deluxeAlbumsIdsMap = rootProfile.deluxeAlbumsIdsMap || {};
        maps.digitalPrintsIdsMap = rootProfile.digitalPrintsIdsMap || {};
        maps.digitalAlbumsIdsMap = rootProfile.digitalAlbumsIdsMap || {};
        maps.exlibrisPrintsIdsMap = rootProfile.exlibrisPrintsIdsMap || {};
        maps.exlibrisAlbumsIdsMap = rootProfile.exlibrisAlbumsIdsMap || {};
        maps.forSalePrintsIdsMap = rootProfile.forSalePrintsIdsMap || {};
        maps.forSaleAlbumsIdsMap = rootProfile.forSaleAlbumsIdsMap || {};
        maps.limitedPrintsIdsMap = rootProfile.limitedPrintsIdsMap || {};
        maps.limitedAlbumsIdsMap = rootProfile.limitedAlbumsIdsMap || {};
        maps.buyPricePrintsIdsMap = rootProfile.buyPricePrintsIdsMap || {};
        maps.resellValuePrintsIdsMap = rootProfile.resellValuePrintsIdsMap || {};
        maps.conditionPrintsIdsMap = rootProfile.conditionPrintsIdsMap || {};
        maps.numberingPrintsIdsMap = rootProfile.numberingPrintsIdsMap || {};

        libraryPrintsOptions?.forEach((option) =>
          // fill maps by reference
          getIdsMapFromBinaryFlags(maps, option.albumObjectId, option.printObjectId, option),
        );

        if (!isGuest) {
          state.ownedAlbumsIdsMap = maps.ownedAlbumsIdsMap;
          state.ownedPrintsIdsMap = maps.ownedPrintsIdsMap;
          state.readAlbumsIdsMap = maps.readAlbumsIdsMap;
          state.readPrintsIdsMap = maps.readPrintsIdsMap;
          state.wishlistPrintsIdsMap = maps.wishlistPrintsIdsMap;
          state.wishlistAlbumsIdsMap = maps.wishlistAlbumsIdsMap;
          state.originalEditionsAlbumsIdsMap = maps.originalEditionsAlbumsIdsMap;
          state.originalEditionsPrintsIdsMap = maps.originalEditionsPrintsIdsMap;
          state.loanAlbumsIdsMap = maps.loanAlbumsIdsMap;
          state.loanPrintsIdsMap = maps.loanPrintsIdsMap;
          state.signedPrintsIdsMap = maps.signedPrintsIdsMap;
          state.signedAlbumsIdsMap = maps.signedAlbumsIdsMap;
          state.memoPrintsIdsMap = maps.memoPrintsIdsMap;
          state.temporaryAddedToLibraryPrintsMap = maps.temporaryAddedToLibraryPrintsMap;
          state.temporaryRemovedFromLibraryPrintsMap = maps.temporaryRemovedFromLibraryPrintsMap;
          state.deluxePrintsIdsMap = maps.deluxePrintsIdsMap;
          state.deluxeAlbumsIdsMap = maps.deluxeAlbumsIdsMap;
          state.digitalPrintsIdsMap = maps.digitalPrintsIdsMap;
          state.digitalAlbumsIdsMap = maps.digitalAlbumsIdsMap;
          state.exlibrisPrintsIdsMap = maps.exlibrisPrintsIdsMap;
          state.exlibrisAlbumsIdsMap = maps.exlibrisAlbumsIdsMap;
          state.forSalePrintsIdsMap = maps.forSalePrintsIdsMap;
          state.forSaleAlbumsIdsMap = maps.forSaleAlbumsIdsMap;
          state.limitedPrintsIdsMap = maps.limitedPrintsIdsMap;
          state.limitedAlbumsIdsMap = maps.limitedAlbumsIdsMap;
          state.buyPricePrintsIdsMap = maps.buyPricePrintsIdsMap;
          state.resellValuePrintsIdsMap = maps.resellValuePrintsIdsMap;
          state.conditionPrintsIdsMap = maps.conditionPrintsIdsMap;
          state.numberingPrintsIdsMap = maps.numberingPrintsIdsMap;
        } else {
          const otherProfiles = state.otherProfiles;
          otherProfiles[libraryObjectId] = {
            ...(otherProfiles[libraryObjectId] || rootProfile),
            ...maps,
          };

          state.otherProfiles = otherProfiles;
        }

        state.loading[LOAD_LIBRARY_PRINTS_OPTIONS] = false;
        state.errors[LOAD_LIBRARY_PRINTS_OPTIONS] = null;
      })
      .addCase(loadLibraryPrintsOptions.rejected, (state, action) => {
        state.loading[LOAD_LIBRARY_PRINTS_OPTIONS] = false;
        state.errors[LOAD_LIBRARY_PRINTS_OPTIONS] = action.error;
      })
      // loadFollowed
      .addCase(loadFollowed.pending, (state, action) => {
        state.loading[LOAD_FOLLOWED] = true;
        state.errors[LOAD_FOLLOWED] = null;
      })
      .addCase(loadFollowed.fulfilled, (state, action) => {
        const { isGuest, libraryObjectId, followed } = action.payload;

        const rootProfile = isGuest
          ? state.otherProfiles[libraryObjectId] || { ...initialProfile }
          : { ...state };

        const followedSeriesIdsMap = { ...rootProfile.followedSeriesIdsMap };

        // immediately populate albums map
        let myAlbums = { ...rootProfile.myAlbums };
        followed?.forEach((serie) =>
          serie.albums.map(
            (album) =>
              (myAlbums[album.objectId] = Object.assign(
                {},
                myAlbums[album.objectId] || {},
                formatAlbum(album),
              )),
          ),
        );

        // create the formattedReceivedLibrary
        const formattedReceivedFollowedLibrary = formatAndUpdateReceivedLibraryArray(followed);
        formattedReceivedFollowedLibrary.forEach((serie) => {
          const serieObjectId = serie.serie.objectId;
          if (serie.followed) {
            followedSeriesIdsMap[serieObjectId] = true;
          }
        });

        const myCompletionSeries = createCompletionData(
          deepCloneLibraryArray(rootProfile.formattedReceivedLibrary),
          formattedReceivedFollowedLibrary,
          rootProfile.unfollowedSeriesIdsMap,
        ).map((serie) => {
          // keeping only album not in library, either read or not
          serie.albums = serie.albums.filter(
            (albumObjectId) => !rootProfile.ownedAlbumsIdsMap[albumObjectId],
          );
          return serie;
        });

        if (!isGuest) {
          state.followedSeriesIdsMap = followedSeriesIdsMap;
          state.formattedReceivedFollowedLibrary = formattedReceivedFollowedLibrary;
          state.myAlbums = myAlbums;
          state.myCompletionSeries = myCompletionSeries;
        } else {
          const otherProfiles = state.otherProfiles;
          otherProfiles[libraryObjectId] = otherProfiles[libraryObjectId] || rootProfile;
          const otherProfile = otherProfiles[libraryObjectId];
          otherProfile.followedSeriesIdsMap = followedSeriesIdsMap;
          otherProfile.formattedReceivedFollowedLibrary = formattedReceivedFollowedLibrary;
          otherProfile.myAlbums = myAlbums;
          otherProfile.myCompletionSeries = myCompletionSeries;
          state.otherProfiles = otherProfiles;
        }

        state.loading[LOAD_FOLLOWED] = false;
        state.errors[LOAD_FOLLOWED] = null;
      })
      .addCase(loadFollowed.rejected, (state, action) => {
        state.loading[LOAD_FOLLOWED] = false;
        state.errors[LOAD_FOLLOWED] = action.error;
      })
      // loadUserAgenda
      .addCase(loadUserAgenda.pending, (state, action) => {
        state.pastMyAgendaLoaded = false;

        state.loading[LOAD_USER_AGENDA] = true;
        state.errors[LOAD_USER_AGENDA] = null;
      })
      .addCase(loadUserAgenda.fulfilled, (state, action) => {
        const myAgenda = action.payload.myAgenda;
        if (!action.payload.isGuest) {
          state.pastMyAgendaLoaded = !!action.payload.months;
          state.myAgenda = myAgenda;
        } else {
          const otherProfiles = state.otherProfiles;
          otherProfiles[action.payload.libraryObjectId] =
            otherProfiles[action.payload.libraryObjectId] || Object.assign({}, initialProfile);
          const otherProfile = otherProfiles[action.payload.libraryObjectId];
          otherProfile.myAgenda = myAgenda;
          state.otherProfiles = otherProfiles;
        }

        state.loading[LOAD_USER_AGENDA] = false;
        state.errors[LOAD_USER_AGENDA] = null;
      })
      .addCase(loadUserAgenda.rejected, (state, action) => {
        state.loading[LOAD_USER_AGENDA] = false;
        state.errors[LOAD_USER_AGENDA] = action.error;
      })
      // loadUserStats
      .addCase(loadUserStats.pending, (state, action) => {
        state.loading[LOAD_USER_STATS] = true;
        state.errors[LOAD_USER_STATS] = null;
      })
      .addCase(loadUserStats.fulfilled, (state, action) => {
        const stats = action.payload.stats;
        if (!action.payload.isGuest) {
          state.stats = stats;
        } else {
          const otherProfiles = state.otherProfiles;
          otherProfiles[action.payload.libraryObjectId] =
            otherProfiles[action.payload.libraryObjectId] || Object.assign({}, initialProfile);
          const otherProfile = otherProfiles[action.payload.libraryObjectId];
          otherProfile.stats = stats;
          state.otherProfiles = otherProfiles;
        }

        state.loading[LOAD_USER_STATS] = false;
        state.errors[LOAD_USER_STATS] = null;
      })
      .addCase(loadUserStats.rejected, (state, action) => {
        state.loading[LOAD_USER_STATS] = false;
        state.errors[LOAD_USER_STATS] = action.error;
      })
      // loadSignedAlbums
      .addCase(loadSignedAlbums.pending, (state, action) => {
        state.loading[LOAD_SIGNED_ALBUMS] = true;
        state.errors[LOAD_SIGNED_ALBUMS] = null;
      })
      .addCase(loadSignedAlbums.fulfilled, (state, action) => {
        const signedAlbumsIdsMap = {};
        action.payload.albums.forEach((album) => (signedAlbumsIdsMap[album.objectId] = true));

        if (!action.payload.isGuest) {
          state.signedAlbumsIdsMap = signedAlbumsIdsMap;
        } else {
          const otherProfiles = state.otherProfiles;
          otherProfiles[action.payload.libraryObjectId] =
            otherProfiles[action.payload.libraryObjectId] || Object.assign({}, initialProfile);
          const otherProfile = otherProfiles[action.payload.libraryObjectId];
          otherProfile.signedAlbumsIdsMap = signedAlbumsIdsMap;
          state.otherProfiles = otherProfiles;
        }

        state.loading[LOAD_SIGNED_ALBUMS] = false;
        state.errors[LOAD_SIGNED_ALBUMS] = null;
      })
      .addCase(loadSignedAlbums.rejected, (state, action) => {
        state.loading[LOAD_SIGNED_ALBUMS] = false;
        state.errors[LOAD_SIGNED_ALBUMS] = action.error;
      })
      // loadLoanedAlbums
      .addCase(loadLoanedAlbums.pending, (state, action) => {
        state.loading[LOAD_LOANED_ALBUMS] = true;
        state.errors[LOAD_LOANED_ALBUMS] = null;
      })
      .addCase(loadLoanedAlbums.fulfilled, (state, action) => {
        const loanedAlbums = action.payload.loanedAlbums;
        const loanAlbumsIdsMap = loanedAlbums.reduce((acc, cur) => {
          acc[cur.objectId] = cur?.extraUserOptions?.loan;
          return acc;
        }, {});

        if (!action.payload.isGuest) {
          state.loanAlbums = loanedAlbums;
          state.loanAlbumsIdsMap = loanAlbumsIdsMap;
        } else {
          const otherProfiles = state.otherProfiles;
          otherProfiles[action.payload.libraryObjectId] =
            otherProfiles[action.payload.libraryObjectId] || Object.assign({}, initialProfile);
          const otherProfile = otherProfiles[action.payload.libraryObjectId];
          otherProfile.loanAlbumsIdsMap = loanAlbumsIdsMap;
          state.otherProfiles = otherProfiles;
        }

        state.loading[LOAD_LOANED_ALBUMS] = false;
        state.errors[LOAD_LOANED_ALBUMS] = null;
      })
      .addCase(loadLoanedAlbums.rejected, (state, action) => {
        state.loading[LOAD_LOANED_ALBUMS] = false;
        state.errors[LOAD_LOANED_ALBUMS] = action.error;
      })
      // loadWishlistAlbums
      .addCase(loadWishlistAlbums.pending, (state, action) => {
        state.loading[LOAD_WISHLIST_ALBUMS] = true;
        state.errors[LOAD_WISHLIST_ALBUMS] = null;
      })
      .addCase(loadWishlistAlbums.fulfilled, (state, action) => {
        let wishlistAlbumsIdsMap = {};
        let wishlistPrintsIdsMap = {};
        action.payload.wishlistAlbums.forEach((album) => {
          wishlistAlbumsIdsMap[album.objectId] = album.extraUserOptions.wishlist;
          (album.userPrints || []).forEach((print) => {
            wishlistPrintsIdsMap[print.objectId] = album.extraUserOptions.wishlist;
          });
        });

        if (!action.payload.isGuest) {
          state.wishlistAlbumsIdsMap = wishlistAlbumsIdsMap;
          state.wishlistPrintsIdsMap = wishlistPrintsIdsMap;
        } else {
          const otherProfiles = state.otherProfiles;
          otherProfiles[action.payload.libraryObjectId] =
            otherProfiles[action.payload.libraryObjectId] || Object.assign({}, initialProfile);
          const otherProfile = otherProfiles[action.payload.libraryObjectId];
          otherProfile.wishlistAlbumsIdsMap = wishlistAlbumsIdsMap;
          otherProfile.wishlistPrintsIdsMap = wishlistPrintsIdsMap;
          state.otherProfiles = otherProfiles;
        }

        state.loading[LOAD_WISHLIST_ALBUMS] = false;
        state.errors[LOAD_WISHLIST_ALBUMS] = null;
      })
      .addCase(loadWishlistAlbums.rejected, (state, action) => {
        state.loading[LOAD_WISHLIST_ALBUMS] = false;
        state.errors[LOAD_WISHLIST_ALBUMS] = action.error;
      })
      // loadSelectedAlbumPrints
      .addCase(loadSelectedAlbumPrints.pending, (state, action) => {
        state.loading[LOAD_SELECTED_ALBUM_PRINTS] = true;
        state.errors[LOAD_SELECTED_ALBUM_PRINTS] = null;
      })
      .addCase(loadSelectedAlbumPrints.fulfilled, (state, action) => {
        const selectedAlbumPrintObjectIdsMap = {};
        action.payload.selectedPrints.forEach(
          (pair) => (selectedAlbumPrintObjectIdsMap[pair.album.objectId] = pair.objectId),
        );

        if (!action.payload.isGuest) {
          state.selectedAlbumPrintObjectIdsMap = selectedAlbumPrintObjectIdsMap;
        } else {
          const otherProfiles = state.otherProfiles;
          otherProfiles[action.payload.libraryObjectId] =
            otherProfiles[action.payload.libraryObjectId] || Object.assign({}, initialProfile);
          const otherProfile = otherProfiles[action.payload.libraryObjectId];
          otherProfile.selectedAlbumPrintObjectIdsMap = selectedAlbumPrintObjectIdsMap;
          state.otherProfiles = otherProfiles;
        }

        state.loading[LOAD_SELECTED_ALBUM_PRINTS] = false;
        state.errors[LOAD_SELECTED_ALBUM_PRINTS] = null;
      })
      .addCase(loadSelectedAlbumPrints.rejected, (state, action) => {
        state.loading[LOAD_SELECTED_ALBUM_PRINTS] = false;
        state.errors[LOAD_SELECTED_ALBUM_PRINTS] = action.error;
      })
      // loadRecommendation
      .addCase(loadRecommendation.pending, (state, action) => {
        state.loading[LOAD_RECOMMENDATION] = true;
        state.errors[LOAD_RECOMMENDATION] = null;
      })
      .addCase(loadRecommendation.fulfilled, (state, action) => {
        state.recommendedAlbumsIdsMap = action.payload.albums.reduce((acc, cur) => {
          acc[cur.objectId] = true;
          return acc;
        }, {});

        state.loading[LOAD_RECOMMENDATION] = false;
        state.errors[LOAD_RECOMMENDATION] = null;
      })
      .addCase(loadRecommendation.rejected, (state, action) => {
        state.loading[LOAD_RECOMMENDATION] = false;
        state.errors[LOAD_RECOMMENDATION] = action.error;
      })
      // loadLibraryAuthorFollow
      .addCase(loadLibraryAuthorFollow.pending, (state, action) => {
        state.loading[LOAD_LIBRARY_AUTHOR_FOLLOW] = true;
        state.errors[LOAD_LIBRARY_AUTHOR_FOLLOW] = null;
      })
      .addCase(loadLibraryAuthorFollow.fulfilled, (state, action) => {
        const followedAuthorsIdsMap = (action.payload.authors || []).reduce((acc, cur) => {
          acc[cur.objectId] = true;
          return acc;
        }, {});
        state.followedAuthorsIdsMap = followedAuthorsIdsMap;

        state.loading[LOAD_LIBRARY_AUTHOR_FOLLOW] = false;
        state.errors[LOAD_LIBRARY_AUTHOR_FOLLOW] = null;
      })
      .addCase(loadLibraryAuthorFollow.rejected, (state, action) => {
        state.loading[LOAD_LIBRARY_AUTHOR_FOLLOW] = false;
        state.errors[LOAD_LIBRARY_AUTHOR_FOLLOW] = action.error;
      })
      // addAuthorToFollow
      .addCase(addAuthorToFollow.pending, (state, action) => {
        state.followedAuthorsIdsMap[action.meta.arg.authorObjectId] = true;

        state.loading[ADD_AUTHOR_TO_FOLLOW] = true;
        state.errors[ADD_AUTHOR_TO_FOLLOW] = null;
      })
      .addCase(addAuthorToFollow.fulfilled, (state, action) => {
        state.loading[ADD_AUTHOR_TO_FOLLOW] = false;
        state.errors[ADD_AUTHOR_TO_FOLLOW] = null;
      })
      .addCase(addAuthorToFollow.rejected, (state, action) => {
        state.loading[ADD_AUTHOR_TO_FOLLOW] = false;
        state.errors[ADD_AUTHOR_TO_FOLLOW] = action.error;
      })
      // removeAuthorFromFollow
      .addCase(removeAuthorFromFollow.pending, (state, action) => {
        state.followedAuthorsIdsMap[action.meta.arg.authorObjectId] = false;

        state.loading[REMOVE_AUTHOR_FROM_FOLLOW] = true;
        state.errors[REMOVE_AUTHOR_FROM_FOLLOW] = null;
      })
      .addCase(removeAuthorFromFollow.fulfilled, (state, action) => {
        state.loading[REMOVE_AUTHOR_FROM_FOLLOW] = false;
        state.errors[REMOVE_AUTHOR_FROM_FOLLOW] = null;
      })
      .addCase(removeAuthorFromFollow.rejected, (state, action) => {
        state.loading[REMOVE_AUTHOR_FROM_FOLLOW] = false;
        state.errors[REMOVE_AUTHOR_FROM_FOLLOW] = action.error;
      })
      // addSerieToFollow
      .addCase(addSerieToFollow.pending, (state, action) => {
        const serieObjectId = action.meta.arg.serieObjectId;
        let followedSeriesIdsMap = Object.assign({}, state.followedSeriesIdsMap);
        let unfollowedSeriesIdsMap = Object.assign({}, state.unfollowedSeriesIdsMap);
        followedSeriesIdsMap[serieObjectId] = true;
        delete unfollowedSeriesIdsMap[serieObjectId];
        state.followedSeriesIdsMap = followedSeriesIdsMap;
        state.unfollowedSeriesIdsMap = unfollowedSeriesIdsMap;

        state.loading[ADD_SERIE_TO_FOLLOW] = true;
        state.errors[ADD_SERIE_TO_FOLLOW] = null;
      })
      .addCase(addSerieToFollow.fulfilled, (state, action) => {
        state.loading[ADD_SERIE_TO_FOLLOW] = false;
        state.errors[ADD_SERIE_TO_FOLLOW] = null;
      })
      .addCase(addSerieToFollow.rejected, (state, action) => {
        state.loading[ADD_SERIE_TO_FOLLOW] = false;
        state.errors[ADD_SERIE_TO_FOLLOW] = action.error;
      })
      // removeSerieFromFollow
      .addCase(removeSerieFromFollow.pending, (state, action) => {
        const serieObjectId = action.meta.arg.serieObjectId;
        let followedSeriesIdsMap = Object.assign({}, state.followedSeriesIdsMap);
        let unfollowedSeriesIdsMap = Object.assign({}, state.unfollowedSeriesIdsMap);
        delete followedSeriesIdsMap[serieObjectId];
        unfollowedSeriesIdsMap[serieObjectId] = true;
        state.followedSeriesIdsMap = followedSeriesIdsMap;
        state.unfollowedSeriesIdsMap = unfollowedSeriesIdsMap;

        state.loading[REMOVE_SERIE_FROM_FOLLOW] = true;
        state.errors[REMOVE_SERIE_FROM_FOLLOW] = null;
      })
      .addCase(removeSerieFromFollow.fulfilled, (state, action) => {
        state.loading[REMOVE_SERIE_FROM_FOLLOW] = false;
        state.errors[REMOVE_SERIE_FROM_FOLLOW] = null;
      })
      .addCase(removeSerieFromFollow.rejected, (state, action) => {
        state.loading[REMOVE_SERIE_FROM_FOLLOW] = false;
        state.errors[REMOVE_SERIE_FROM_FOLLOW] = action.error;
      })
      // updateWishlistPrints
      .addCase(updateWishlistPrints.pending, (state, action) => {
        state.loading[UPDATE_WISHLIST_PRINTS] = true;
        state.errors[UPDATE_WISHLIST_PRINTS] = null;
      })
      .addCase(updateWishlistPrints.fulfilled, (state, action) => {
        state.loading[UPDATE_WISHLIST_PRINTS] = false;
        state.errors[UPDATE_WISHLIST_PRINTS] = null;
      })
      .addCase(updateWishlistPrints.rejected, (state, action) => {
        state.loading[UPDATE_WISHLIST_PRINTS] = false;
        state.errors[UPDATE_WISHLIST_PRINTS] = action.error;
      })
      // updateLibrary
      .addCase(updateLibrary.pending, (state, action) => {
        state.loading[UPDATE_LIBRARY] = true;
        state.errors[UPDATE_LIBRARY] = null;
      })
      .addCase(updateLibrary.fulfilled, (state, action) => {
        state.loading[UPDATE_LIBRARY] = false;
        state.errors[UPDATE_LIBRARY] = null;
      })
      .addCase(updateLibrary.rejected, (state, action) => {
        state.loading[UPDATE_LIBRARY] = false;
        state.errors[UPDATE_LIBRARY] = action.error;
      });
  },
});

export default profilesSlice.reducer;

export const {
  resetProfilesReducer,
  setEoFlagOnPrints,
  setReadFlagOnPrints,
  setSignedFlagOnPrints,
  setDeluxeFlagOnPrints,
  setLimitedFlagOnPrints,
  setExlibrisFlagOnPrints,
  setDigitalFlagOnPrints,
  setForSaleFlagOnPrints,
  setNumberingFlagOnPrints,
  setBuyPriceFlagOnPrints,
  setResellValueFlagOnPrints,
  setConditionFlagOnPrints,
  updateMemoPrints,
  addPrintsToLibrary,
  removePrintsFromLibrary,
  addPrintsToLoan,
  removePrintsFromLoan,
  addPrintsToWishlist,
  removePrintsFromWishlist,
} = profilesSlice.actions;

// HELPERS ================================================================================

const createdTitleIndexed = (serie) => {
  // add a default serie title because we must have one,
  // with '###' to get it at the top of the ordered list
  var titleIndexed = serie.title ? serie.title.toLowerCase() : '###';
  var twoFirst = titleIndexed.slice(0, 2);
  if (twoFirst === "l'") {
    titleIndexed = titleIndexed.slice(2);
  }
  var threeFirst = titleIndexed.slice(0, 3);
  if (threeFirst === 'le ' || threeFirst === 'la ') {
    titleIndexed = titleIndexed.slice(3);
  }
  var fourFirst = titleIndexed.slice(0, 4);
  if (fourFirst === 'les ') {
    titleIndexed = titleIndexed.slice(4);
  }
  return titleIndexed;
};

const deepCloneLibraryArray = (libraryArray = []) => {
  return libraryArray.map((serie) => {
    try {
      let newSerie = Object.assign({}, serie);
      newSerie.serie = Object.assign({}, serie.serie);
      newSerie.albums = serie.albums.slice();

      return newSerie;
    } catch (error) {
      throw error;
    }
  });
};

const createCompletionData = (
  formattedReceivedLibrary = [],
  formattedReceivedFollowedLibrary = [],
  unfollowedSeriesIdsMap = {},
) => {
  // creating a indexed array of the follow/unfollow series
  let followedSeriesMap = {};
  // formattedReceivedFollowedLibrary only contains followed infos
  formattedReceivedFollowedLibrary.forEach((serie) => {
    followedSeriesMap[serie.serie.objectId] = { ...serie };
  });
  // formattedReceivedLibrary contains followed & unfollowed infos, but not for empty series
  formattedReceivedLibrary.forEach((serie) => {
    followedSeriesMap[serie.serie.objectId] = { ...serie };
  });

  // going through all the owned series
  const completionSeries = formattedReceivedLibrary.filter((serie) => {
    const serieObjectId = serie.serie.objectId;
    // look for the same series in the follow/unfollow series
    const followedInformations = followedSeriesMap[serieObjectId];

    // we don't have a follow/unfollow info, so it is an implicit follow
    if (!followedInformations) {
      return true;
    } else {
      // we have a follow/unfollow info
      if (unfollowedSeriesIdsMap[serieObjectId]) {
        // it is an explicit unfollow,
        // we delete the serie from the follow/unfollow series and don't add it
        delete followedSeriesMap[serieObjectId];
        return false;
      } else {
        // it is an explicit follow,
        // we delete the serie from the follow/unfollow series and do add it
        delete followedSeriesMap[serieObjectId];
        return true;
      }
    }
  });

  // we then add the remaining series from the follow/unfollow series, only if they are explicitly followed
  const remainingFollowedSeries = Object.values(followedSeriesMap).filter(
    (serie) => serie.followed,
  );

  // we concatenate the remainings series, sort them because of the concat which added series in the array
  // and then remove the already completed series
  return completionSeries
    .concat(remainingFollowedSeries)
    .sort((a, b) => a.serie.titleIndexed.localeCompare(b.serie.titleIndexed))
    .filter((serie) => serie.albumsInSerie !== serie.albumsOwned);
};

const formatAndUpdateReceivedLibraryArray = (receivedLibrary) =>
  receivedLibrary
    .map((serie) => {
      serie.serie.titleIndexed = createdTitleIndexed(serie.serie);

      // translate every object album to an objectId
      serie.albums = serie.albums.reverse().map((album) => album.objectId);
      return serie;
    })
    // order based on serie title indexed
    .sort((a, b) => a.serie.titleIndexed.localeCompare(b.serie.titleIndexed));

//TODO: isPublished should use print info
const formatAlbum = (album) => {
  const newAlbum = Object.assign({}, album, {
    isPublished: !album.publicationDate || new Date(album.publicationDate) < new Date(),
  });

  // format dates
  newAlbum.publicationDateFormattedAll = null;
  newAlbum.publicationDateFormattedShort = null;
  if (album.publicationDate && new Date(album.publicationDate)) {
    const publicationDate = new Date(album.publicationDate);
    newAlbum.publicationDateFormattedAll = formatAsFrenchUtcDoubleDigitFromIso(publicationDate);
    newAlbum.publicationDateFormattedShort = formatAsFrenchUtcDoubleDigitFromIso(
      publicationDate,
      false,
    );
  }
  return newAlbum;
};

// this is used to display good informations while loadLibrary & loadUserAgenda are delayed
const updateArrays = (
  state,
  temporaryAddedToLibraryAlbumsMap,
  temporaryRemovedFromLibraryAlbumsMap,
) => {
  const ownedAlbumsIdsMap = state.ownedAlbumsIdsMap;
  const readAlbumsIdsMap = state.readAlbumsIdsMap;

  const myCompletionSeries = createCompletionData(
    deepCloneLibraryArray(state.formattedReceivedLibrary),
    deepCloneLibraryArray(state.formattedReceivedFollowedLibrary),
    state.unfollowedSeriesIdsMap,
  )
    .map((serie) => {
      // keeping only album not in library & not tmp removed, either read or not
      serie.albums = serie.albums.filter(
        (albumId) => !ownedAlbumsIdsMap[albumId] && !temporaryAddedToLibraryAlbumsMap[albumId],
      );
      // if all albums are owned, remove serie from completionSeries
      if (!serie.albums.length) {
        return null;
      }
      return serie;
    })
    .filter((row) => row);

  let mySeries = deepCloneLibraryArray(state.formattedReceivedLibrary).map((serie) => {
    // keeping only album read or in library
    serie.albums = serie.albums.filter(
      (albumId) =>
        readAlbumsIdsMap[albumId] ||
        (!!ownedAlbumsIdsMap[albumId] && !temporaryRemovedFromLibraryAlbumsMap[albumId]),
    );
    return serie;
  });

  return [myCompletionSeries, mySeries];
};

const getIdsMapFromBinaryFlags = (maps, albumObjectId, printObjectId, printOptions) => {
  const flags = printOptions?.optionsBinaryFlags || printOptions;
  if (isBinaryFlagEnabled(flags, GENERAL.USER_PRINT.OWNED)) {
    if (!maps.temporaryRemovedFromLibraryPrintsMap[printObjectId]) {
      maps.ownedPrintsIdsMap[printObjectId] = true;
      maps.ownedAlbumsIdsMap[albumObjectId] = (maps.ownedAlbumsIdsMap[albumObjectId] || [])
        .concat([printObjectId])
        .filter((item, pos, self) => self.indexOf(item) == pos)
        .sort((a, b) => a > b);
      // we should use what above instead of the array for each key
      // maps.ownedAlbumsIdsMap[albumObjectId] = true;
    }
  }
  if (isBinaryFlagEnabled(flags, GENERAL.USER_PRINT.READ)) {
    maps.readPrintsIdsMap[printObjectId] = true;
    maps.readAlbumsIdsMap[albumObjectId] = true;
  }
  if (isBinaryFlagEnabled(flags, GENERAL.USER_PRINT.ORIGINAL_EDITION)) {
    maps.originalEditionsPrintsIdsMap[printObjectId] = true;
    maps.originalEditionsAlbumsIdsMap[albumObjectId] = true;
  }
  if (isBinaryFlagEnabled(flags, GENERAL.USER_PRINT.SIGNED)) {
    maps.signedPrintsIdsMap[printObjectId] = printOptions?.autographImagePath || true;
    maps.signedAlbumsIdsMap[albumObjectId] = true;
  }
  if (isBinaryFlagEnabled(flags, GENERAL.USER_PRINT.WISHLIST)) {
    maps.wishlistPrintsIdsMap[printObjectId] = printOptions?.wishlist || {};
    maps.wishlistAlbumsIdsMap[albumObjectId] = true;
  }
  if (isBinaryFlagEnabled(flags, GENERAL.USER_PRINT.DELUXE)) {
    maps.deluxePrintsIdsMap[printObjectId] = printOptions?.deluxeImagePath || true;
    maps.deluxeAlbumsIdsMap[albumObjectId] = true;
  }
  if (isBinaryFlagEnabled(flags, GENERAL.USER_PRINT.DIGITAL)) {
    maps.digitalPrintsIdsMap[printObjectId] = true;
    maps.digitalAlbumsIdsMap[albumObjectId] = true;
  }
  if (isBinaryFlagEnabled(flags, GENERAL.USER_PRINT.EXLIBRIS)) {
    maps.exlibrisPrintsIdsMap[printObjectId] = printOptions?.exLibrisImagePath || true;
    maps.exlibrisAlbumsIdsMap[albumObjectId] = true;
  }
  if (isBinaryFlagEnabled(flags, GENERAL.USER_PRINT.LIMITED)) {
    maps.limitedPrintsIdsMap[printObjectId] = true;
    maps.limitedAlbumsIdsMap[albumObjectId] = true;
  }
  if (isBinaryFlagEnabled(flags, GENERAL.USER_PRINT.LOAN)) {
    maps.loanPrintsIdsMap[printObjectId] = printOptions?.loan || {};
    maps.loanAlbumsIdsMap[albumObjectId] = true;
  }
  if (isBinaryFlagEnabled(flags, GENERAL.USER_PRINT.FOR_SALE)) {
    maps.forSalePrintsIdsMap[printObjectId] = true;
    maps.forSaleAlbumsIdsMap[albumObjectId] = true;
  }
  if (isBinaryFlagEnabled(flags, GENERAL.USER_PRINT.MEMO)) {
    maps.memoPrintsIdsMap[printObjectId] = printOptions?.memo || true;
  }
  if (printOptions?.buyPrice) {
    maps.buyPricePrintsIdsMap[printObjectId] = printOptions?.buyPrice || null;
  }
  if (printOptions?.resellValue) {
    maps.resellValuePrintsIdsMap[printObjectId] = printOptions?.resellValue || null;
  }
  if (printOptions?.condition) {
    maps.conditionPrintsIdsMap[printObjectId] = printOptions?.condition || null;
  }
  if (printOptions?.numbering) {
    maps.numberingPrintsIdsMap[printObjectId] = printOptions?.numbering || null;
  }
  return maps;
};
