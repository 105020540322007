import { createSelector } from '@reduxjs/toolkit';

import { initialProfile } from './reducers/profiles';

import bubbleUtils from 'bubble-utils';

export const getClientToken = (state) => state.auth.jwtToken;

export const getUserOrGuest = (state) => {
  const user = state.user.user;
  if (!Object.keys(user).length) {
    return state.user.guest;
  }
  return user;
};

export const getProductType = createSelector(
  [(state) => state.products.products, (_, type) => type],
  (products, type) => {
    return Object.values(products).filter((product) => product.type === type);
  },
);

export const getClientId = (state) =>
  getUserId(state) ? getUserId(state) : getGuestId(state) ? getGuestId(state) : getSessionId(state);
export const getUserIdOrSessionId = (state) =>
  getUserId(state) ? getUserId(state) : getSessionId(state);

export const getUserId = (state) => state.user.user?.objectId;
export const getSessionId = (state) => state.auth.sessionId;
export const getGuestId = (state) => state.user.guest?.objectId;

export const getUserFavoriteStoresIds = (state) => (state.user || {}).favoriteStoresIds || [];
export const getStoresReducer = (state) => state.stores || {};
export const getHitsStores = (state) =>
  ((state.search || {}).hitsStores || { type: 'store', data: [] }).data || [];
export const getAlbums = (state) => state.albums.albums;
export const getAuthors = (state) => state.authors.authors;
export const getAuthor = (state, authorObjectId) => getAuthors(state)[authorObjectId];
export const getSeries = (state) => state.series.series;
export const getArticles = (state) => state.articles.articlesMapByObjectId;

export const getFiltersFromId = createSelector(
  [(state) => state.filters.filters, (state, filterId) => filterId],
  (filters, filterId) => filters[filterId] || {},
);

export const getAgendaAlbums = createSelector(
  [
    (state) => state.agendas.agendaAlbumObjectIds,
    getAlbums,
    (_, category) => category || null,
    (_, _2, ignoreWithoutImages) => ignoreWithoutImages,
  ],
  (agendaAlbumObjectIds, albums, category, ignoreWithoutImages) => {
    return agendaAlbumObjectIds
      .map((objectId) => {
        const album = albums[objectId];
        if (ignoreWithoutImages && !album.images) return null;
        if (!category) return album;
        return album?.serie?.category?.toLowerCase() === category.toLowerCase() ? album : null;
      })
      .filter(Boolean);
  },
);

export const getMyAgendaAlbums = createSelector(
  [
    (state, userObjectId) => getUserProfile(state, userObjectId).myAgenda,
    getAlbums,
    (state, userObjectId) => getUserProfile(state, userObjectId).ownedAlbumsIdsMap,
  ],
  (myAgenda, albums, ownedAlbumsIdsMap) =>
    myAgenda
      .map((agendaAlbum) => {
        return {
          ...albums[agendaAlbum.albumObjectId],
          agendaPrintObjectId: agendaAlbum.printObjectId,
          publicationDate: agendaAlbum.publicationDate,
        };
      })
      .filter((album) => !ownedAlbumsIdsMap[album.objectId]),
);

export const getStockForStore = (state, storeObjectId) =>
  state.stores.itemsAvailabilitiesPerStores.find((store) => store.objectId === storeObjectId);

export const getRecoAlbumIds = createSelector(
  [(state) => state.profiles.recommendedAlbumsIdsMap, (_, max) => max],
  (recommendedAlbumsIdsMap, max) => Object.keys(recommendedAlbumsIdsMap).slice(0, max || 10),
);

export const getSerie = (state, serieObjectId) => getSeries(state)[serieObjectId];
export const getSeriesFromIds = createSelector(
  [getSeries, (_, serieObjectIds) => serieObjectIds],
  (series, ids) => ids.map((id) => series[id]).filter(Boolean),
);

export const getUserProfile = (state, userObjectId = null) => {
  if (!userObjectId) return state.profiles;
  const profiles = state.profiles.otherProfiles;

  const userProfile = profiles[userObjectId] || Object.assign({}, initialProfile);
  return userProfile;
};

export const isAlbumOwned = (state, albumObjectId, userObjectId = null) =>
  !!getUserProfile(state, userObjectId).ownedAlbumsIdsMap[albumObjectId];
export const isAlbumSigned = (state, albumObjectId, userObjectId = null) =>
  !!getUserProfile(state, userObjectId).signedAlbumsIdsMap[albumObjectId];
export const isAlbumRead = (state, albumObjectId, userObjectId = null) =>
  !!getUserProfile(state, userObjectId).readAlbumsIdsMap[albumObjectId];
export const isAlbumEo = (state, albumObjectId, userObjectId = null) =>
  !!getUserProfile(state, userObjectId).originalEditionsAlbumsIdsMap[albumObjectId];
export const isAlbumLoaned = (state, albumObjectId, userObjectId = null) =>
  getUserProfile(state, userObjectId).loanAlbumsIdsMap[albumObjectId];
export const isAlbumInWishlist = (state, albumObjectId, userObjectId = null) =>
  getUserProfile(state, userObjectId).wishlistAlbumsIdsMap[albumObjectId];

export const isPrintOwned = (state, printObjectId, userObjectId = null) =>
  !!getUserProfile(state, userObjectId).ownedPrintsIdsMap[printObjectId];
export const isPrintSigned = (state, printObjectId, userObjectId = null) =>
  !!getUserProfile(state, userObjectId).signedPrintsIdsMap[printObjectId];
export const isPrintRead = (state, printObjectId, userObjectId = null) =>
  !!getUserProfile(state, userObjectId).readPrintsIdsMap[printObjectId];
export const isPrintEo = (state, printObjectId, userObjectId = null) =>
  !!getUserProfile(state, userObjectId).originalEditionsPrintsIdsMap[printObjectId];
export const isPrintLoaned = (state, printObjectId, userObjectId = null) =>
  getUserProfile(state, userObjectId).loanPrintsIdsMap[printObjectId];
export const isPrintInWishlist = (state, printObjectId, userObjectId = null) =>
  getUserProfile(state, userObjectId).wishlistPrintsIdsMap[printObjectId];
export const isPrintDeluxe = (state, printObjectId, userObjectId = null) =>
  getUserProfile(state, userObjectId).deluxePrintsIdsMap[printObjectId];
export const isPrintLimited = (state, printObjectId, userObjectId = null) =>
  getUserProfile(state, userObjectId).limitedPrintsIdsMap[printObjectId];
export const isPrintWithExLibris = (state, printObjectId, userObjectId = null) =>
  getUserProfile(state, userObjectId).exlibrisPrintsIdsMap[printObjectId];
export const isPrintDigital = (state, printObjectId, userObjectId = null) =>
  getUserProfile(state, userObjectId).digitalPrintsIdsMap[printObjectId];
export const isPrintForSale = (state, printObjectId, userObjectId = null) =>
  getUserProfile(state, userObjectId).forSalePrintsIdsMap[printObjectId];

export const isSerieFollowed = (state, serieObjectId) =>
  !!state.profiles.followedSeriesIdsMap[serieObjectId] || false;

export const getWishlistPrints = createSelector(
  [
    (state, userObjectId) => getUserProfile(state, userObjectId).wishlistPrintsIdsMap,
    (state) => state.prints.prints,
  ],
  (wishlistPrintsIdsMap, prints) => {
    return Object.entries(wishlistPrintsIdsMap)
      .map(([key, value]) => ({ ...prints[key], addedDate: value?.addedDate }))
      .sort((a, b) => new Date(b?.addedDate) - new Date(a?.addedDate))
      .filter(Boolean);
  },
);

export const getUserPrintForAlbum = createSelector(
  [
    (state) => state.prints.prints,
    (state, userObjectId) => getUserProfile(state, userObjectId).selectedAlbumPrintObjectIdsMap,
    (_, userObjectId) => userObjectId,
    (_, defaultPrintObjectId) => defaultPrintObjectId,
    (_, albumObjectId) => albumObjectId,
  ],
  (prints, selectedAlbumPrintObjectIdsMap, userObjectId, defaultPrintObjectId, albumObjectId) => {
    if (!defaultPrintObjectId && !albumObjectId) return {};
    const defaultPrint = prints[defaultPrintObjectId];
    if (!userObjectId) return defaultPrint;

    return prints[selectedAlbumPrintObjectIdsMap[albumObjectId]] || defaultPrint;
  },
);

export const makeGetPreviousAlbumsFromSerie = () =>
  createSelector(
    [
      (state, props) => getSerie(state, props.serieObjectId),
      (_, props) => props.albumObjectId,
      getAlbums,
    ],
    (serie, albumObjectId, albums) => {
      if (!serie) {
        return [];
      }
      const albumIndex = serie.albums?.findIndex(
        (serieAlbumObjectId) => serieAlbumObjectId === albumObjectId,
      );
      if (albumIndex === 0) {
        return [];
      }
      const remainingAlbums = serie.albums
        ?.slice(0, albumIndex)
        .map((albumObjectId) => albums[albumObjectId]);
      return remainingAlbums;
    },
  );

export const makeIsNoPreviousAlbumsOwned = () =>
  createSelector(
    [
      (state) => state.profiles.ownedAlbumsIdsMap,
      (state, props) => getSerie(state, props.serieObjectId),
      (_, props) => props.albumObjectId,
    ],
    (ownedAlbumsIdsMap, serie, albumObjectId) => {
      if (!serie || !serie.albums) {
        return false;
      }

      const albumIndex = serie.albums.findIndex(
        (serieAlbumObjectId) => serieAlbumObjectId === albumObjectId,
      );
      if (albumIndex === 0) {
        return false;
      }
      const remainingAlbumsObjectIds = serie.albums.slice(0, albumIndex);

      return remainingAlbumsObjectIds.every((objectId) => !ownedAlbumsIdsMap[objectId]);
    },
  );

export const makeIsReviewLiked = () =>
  createSelector(
    [(state) => state.user.likes, (_, reviewObjectId) => reviewObjectId],
    (likes, reviewObjectId) => !!likes?.reviews?.find((reviewId) => reviewId === reviewObjectId),
  );

export const makeIsAlbumInCart = () =>
  createSelector(
    [(state) => state.cart.cart, (state, printObjectId) => printObjectId],
    (cart, printObjectId) => !!cart.items.find((item) => item.itemObjectId === printObjectId),
  );

export const getResourcesForArticle = createSelector(
  [
    getAlbums,
    getSeries,
    getAuthors,
    (state) => state.prints.prints,
    (state, articleObjectId) => state.articles.resourcesObjectIdsMap[articleObjectId],
  ],
  (albums, series, authors, prints, resourcesObjectIdsTmp) => {
    let resourcesObjectIds = Object.assign(
      { albums: [], series: [], authors: [], store: [] },
      resourcesObjectIdsTmp,
    );
    if (resourcesObjectIds) {
      if (resourcesObjectIds.albums) {
        resourcesObjectIds.albums = resourcesObjectIds.albums
          .map((albumObjectId) => albums[albumObjectId])
          .filter(Boolean);
        resourcesObjectIds.albums = resourcesObjectIds.albums.map((album) => {
          let newAlbum = Object.assign({}, album);
          newAlbum.prints = (album.prints || [])
            .map((printObjectId) => prints[printObjectId])
            .filter(Boolean);
          return newAlbum;
        });
      }
      if (resourcesObjectIds.series) {
        resourcesObjectIds.series = resourcesObjectIds.series
          .map((albumObjectId) => series[albumObjectId])
          .filter(Boolean);
      }
      if (resourcesObjectIds.authors) {
        resourcesObjectIds.authors = resourcesObjectIds.authors
          .map((authorObjectId) => authors[authorObjectId])
          .filter(Boolean);
      }
    }
    return resourcesObjectIds || {};
  },
);

export const getArticlesForResource = createSelector(
  [getArticles, (state, resourceObjectId) => state.articles.articlesByResourceId[resourceObjectId]],
  (articles, articlesObjectIds) => {
    if (!articlesObjectIds) return [];
    return articlesObjectIds
      .map((objectId) => articles[objectId])
      .filter(Boolean)
      .sort((a, b) => new Date(b.publicationDate) - new Date(a.publicationDate));
  },
);

export const getTopRatedSeries = createSelector(
  [(state) => state.series.mapOfSerieIdsTopRated, getSeries, (_, count) => count],
  (mapOfSerieIdsTopRated, series, count) =>
    (mapOfSerieIdsTopRated['all'] || [])
      .map((serieObjectId) => series[serieObjectId])
      .filter(Boolean)
      .slice(0, count || Infinity),
);

export const getHighlightedArticles = createSelector(
  [
    (state) => state.highlightedResources.highlightedResourcesIdMap.articles,
    (state) => state.articles.highlightedArticleObjectIds,
    getArticles,
  ],
  (highlightedArticles, secondaryHighlightedArticles, articles) => {
    const bank =
      highlightedArticles && highlightedArticles.length
        ? highlightedArticles
        : secondaryHighlightedArticles;
    return bank.map((id) => articles[id]);
  },
);

export const getArticlesFromIds = createSelector([getArticles, (_, ids) => ids], (articles, ids) =>
  ids.map((id) => articles[id]).filter(Boolean),
);

export const getRelatedArticles = createSelector(
  [
    getArticles,
    (state) => state.articles.articlesObjectIdsMapByPermalink,
    (state) => state.articles.relatedArticlesMapByPermalink,
    (_, permalink) => permalink,
  ],
  (
    articlesMapByObjectId,
    articlesObjectIdsMapByPermalink,
    relatedArticlesMapByPermalink,
    permalink,
  ) => {
    const relatedPermalinks = relatedArticlesMapByPermalink[permalink] || null;
    if (!relatedPermalinks?.length) return [];

    return relatedPermalinks
      .map((permalink) => articlesObjectIdsMapByPermalink[permalink])
      .map((objectId) => articlesMapByObjectId[objectId]);
  },
);

export const getArticlesForHomeCategory = createSelector(
  [
    getArticles,
    (state) => state.articles.homeArticlesMapByCategory,
    (_, props) => props.category,
    (_, props) => props.section,
  ],
  (articlesMapByObjectId, homeArticlesMapByCategory, category, section) => {
    return homeArticlesMapByCategory[category || 'all']?.[section]
      ?.map((objectId) => articlesMapByObjectId[objectId])
      .filter((row) => row);
  },
);

export const get4HighlightedResources = createSelector(
  [
    (state) => state.highlightedResources.highlightedResourcesIdMap,
    getSeries,
    getAlbums,
    getAuthors,
    (state) => state.stores.stores,
  ],
  (idsMap, series, albums, authors, stores) => {
    const seriesArray = idsMap.series
      .filter((id) => series[id])
      .map((id) => Object.assign({}, series[id], { identifier: 'serie' }));
    const albumsArray = idsMap.albums
      .filter((id) => albums[id])
      .map((id) => Object.assign({}, albums[id], { identifier: 'album' }));
    const authorsArray = idsMap.authors
      .filter((id) => authors[id])
      .map((id) => Object.assign({}, authors[id], { identifier: 'author' }));
    const storesArray = idsMap.stores
      .filter((id) => stores[id])
      .map((id) => Object.assign({}, stores[id] || {}, { identifier: 'store' }));
    //TODO:9eart we only return series to avoid height discrepencies between series/authors/album cards
    const array = seriesArray
      // .concat(albumsArray)
      // .concat(authorsArray)
      // .concat(storesArray)
      // .sort((a, b) => Math.random() - 0.5)
      .slice(0, 4);

    return {
      series: array.filter((o) => o.identifier === 'serie'),
      albums: array.filter((o) => o.identifier === 'album'),
      authors: array.filter((o) => o.identifier === 'author'),
      stores: array.filter((o) => o.identifier === 'store'),
    };
  },
);

export const getIapsForPlatform = createSelector(
  [(state) => state.inAppPurchases.iaps, (state, platform) => platform],
  (iaps, platform) => iaps.filter((iap) => iap.platform === platform),
);

export const getAlbumsWithMyReviews = createSelector(
  [getAlbums, (state) => state.reviews.myAlbumReviewsMap],
  (albums, myAlbumReviewsMap) => {
    const albumsList = [];
    Object.values(myAlbumReviewsMap).map((reviews) => {
      reviews.map((review) => {
        let album = { ...albums[review.albumObjectId] } || { objectId: review.albumObjectId };
        if (!album.extraInfos) {
          album.extraInfos = { userReview: review };
        } else {
          // TODO: this was done before but there is a `Cannot assign to read only property 'userReview' of
          // object`, the `userReview` should not be added to the `extraInfos` object when loading albums
          // and storing them, it is a user info data not an album data
          //   album.extraInfos.userReview = review;
        }
        albumsList.push(album);
      });
    });
    return albumsList;
  },
);

export const getSeriesWithMyReviews = createSelector(
  [getSeries, (state) => state.reviews.mySerieReviewsMap],
  (series, mySerieReviewsMap) => {
    return Object.values(mySerieReviewsMap).map((review) => {
      let serie = { ...series[review.serieObjectId] } || { objectId: review.serieObjectId };
      if (!serie.extraInfos) {
        serie.extraInfos = { userReview: review };
      } else {
        // TODO: this was done before but there is a `Cannot assign to read only property 'userReview' of
        // object`, the `userReview` should not be added to the `extraInfos` object when loading series
        // and storing them, it is a user info data not an album data
        //   serie.extraInfos.userReview = review;
      }
      return serie;
    });
  },
);

export const getDefaultPaymentMethod = createSelector(
  [(state) => state.paymentMethods.paymentMethods],
  (paymentMethods) => (paymentMethods || []).find((method) => method.isDefault === true) || null,
);

export const makeGetSerieAlbums = () =>
  createSelector(
    [
      (state, serieObjectId) => getSerie(state, serieObjectId)?.albums,
      (state) => state.albums.albums,
    ],
    (serieAlbumsObjectIds, albums) => {
      return serieAlbumsObjectIds && albums
        ? serieAlbumsObjectIds.map((albumObjectId) => albums[albumObjectId])
        : [];
    },
  );

export const makeGetAlbumPrints = () =>
  createSelector(
    (state) => state.prints.prints,
    (state, albumObjectId) => state.albums.albumPrintsObjectIds[albumObjectId],
    (prints, albumPrintsObjectIds) => {
      return albumPrintsObjectIds && prints
        ? albumPrintsObjectIds.map((printObjectId) => prints[printObjectId])
        : [];
    },
  );

export const getAlbumPrintsApp = createSelector(
  [
    (state, albumObjectId) => {
      return state.albums.albumPrintsObjectIds[albumObjectId];
    },
    (state) => state.prints.prints,
  ],
  (albumPrintsObjectIds, prints) => {
    return albumPrintsObjectIds && prints
      ? albumPrintsObjectIds.map((printObjectId) => prints[printObjectId])
      : [];
  },
);

export const getAuthorsFromIds = createSelector(
  [(_, authorObjectIds) => authorObjectIds, getAuthors],
  (authorObjectIds, authors) => {
    return authorObjectIds?.map((id) => authors[id]) || null;
  },
);

export const getAuthorSeriesFromAuthorId = createSelector(
  [
    (state, authorObjectId) => state.authors.authorSeriesObjectIdsMap[authorObjectId],
    (state) => state.authors.authorSeries,
  ],
  (objectIds, series) => (objectIds || []).map((id) => series[id]).filter(Boolean),
);

export const getAlbumIdsForAuthorId = createSelector(
  [
    (state, authorObjectId) =>
      state.albums.albumObjectIdsMapByParams[`?author=${authorObjectId}`]?.albums,
  ],
  (ids) => (ids || []).slice(),
);

export const getAlbumsForAuthorId = createSelector(
  [getAlbums, getAlbumIdsForAuthorId],
  (albums, albumObjectIds) =>
    albumObjectIds
      ?.map((id) => albums[id])
      .filter(Boolean)
      .sort((a, b) => new Date(b.publicationDate) - new Date(a.publicationDate)),
);

export const get3RecoSeries = createSelector(
  [(state, key) => state.series.mapOfSerieIdsCategoriesAndGenres[key], getSeries],
  (seriesIds, series) => {
    const newReco = seriesIds || [];
    const min = 0;
    const max = Math.floor(newReco.length - 3);
    const randomStart = Math.floor(Math.random() * (max - min + 1)) + min;
    const sameGenreSeries = newReco
      .map((serieObjectId) => series[serieObjectId])
      .filter(Boolean)
      .slice(randomStart, randomStart + 3);

    return sameGenreSeries;
  },
);

export const getFirstBanner = createSelector(
  [(state) => state.banners.banners],
  (bannersObject) => {
    const banners = Object.values(bannersObject);
    const firstBanner = banners.reduce((prev, cur) => {
      return Number(prev.position) < Number(cur.position) ? prev : cur;
    }, {});

    return firstBanner;
  },
);

export const getHitsForWebsiteSearchBar = createSelector(
  [(state) => state.search],
  (searchReducer) => {
    return [
      searchReducer[searchReducer.sorting[0]] || null,
      searchReducer[searchReducer.sorting[1]] || null,
      searchReducer[searchReducer.sorting[2]] || null,
      searchReducer[searchReducer.sorting[3]] || null,
      searchReducer[searchReducer.sorting[4]] || null,
    ].filter(Boolean);
  },
);

export const getAlbumsForChristmasId = createSelector(
  (state, id) => id,
  getAlbums,
  (state) => state.albums.xmasSelectionAlbumObjectIdsMapByParams,
  (state) => state.tops.loadedTops,
  (id, albums, xmasSelectionAlbumObjectIdsMapByParams, tops) => {
    if (id === 'tops') {
      return tops['topMostAddedAlbums30Days']?.map((d) => ({
        ...d.album,
        prints: [d.album.selectedPrint],
      }));
    } else {
      const map = {
        oneshot: { tags: 'noel2024,oneshot' },
        jeunesse: { tags: 'noel2024,jeunesse' },
        coffAndInt: { tags: 'noel2024', types: 'intégrale|coffret' },
        integrales: { tags: 'noel2024', types: 'intégrale' },
        coffrets: { tags: 'noel2024', types: 'coffret' },
        bd: { tags: 'noel2024,oneshot', category: 'bd' },
        comics: { tags: 'noel2024,oneshot', category: 'comics' },
        mangas: { tags: 'noel2024,oneshot', category: 'mangas' },
        graphicnovel: { tags: 'noel2024,oneshot,roman graphique' },
        year3: { tags: 'noel2024,+3ans' },
        year6: { tags: 'noel2024,+6ans' },
        year9: { tags: 'noel2024,+9ans' },
        year12: { tags: 'noel2024,+12ans' },
      };

      const objectIds =
        xmasSelectionAlbumObjectIdsMapByParams[bubbleUtils.string.buildSortedQueryString(map[id])];
      return objectIds?.map((id) => albums[id]);
    }
  },
);
