import React, { useEffect } from 'react';

import config from '@/project-config';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { logout } from 'bubble-reducers/src/reducers/auth';

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      logout({
        cookie: { name: config.cookies.jwtToken.name, options: config.cookies.defaultOptions },
        localStorage: { keyName: config.localStorage.user.keyName },
      }),
    );
    navigate('/login');
  }, []);

  return null;
};

export default Logout;
