import PropTypes from 'prop-types';
import React from 'react';

import { useSelector } from 'react-redux';

import {
  isAlbumOwned,
  makeGetPreviousAlbumsFromSerie,
  makeIsNoPreviousAlbumsOwned,
} from 'bubble-reducers/src/selectors';

import {
  useAddPrintsToLibrary,
  useRemovePrintsFromLibrary,
} from '@/services/hooks/useLibraryUtils';
import { getAssetImage } from '@/services/image-utils';

import ButtonWithIcon from '@/components/ButtonWithIcon/ButtonWithIcon';

const HorizontalAddToCollectionZone = (props) => {
  const addPrintsToLibrary = useAddPrintsToLibrary();
  const removePrintsFromLibrary = useRemovePrintsFromLibrary();

  const isNoPreviousAlbumsOwned = makeIsNoPreviousAlbumsOwned();
  const getPreviousAlbumsFromSerie = makeGetPreviousAlbumsFromSerie();

  const album = props.album || {};

  const albumObjectId = album.objectId;
  const serieObjectId = props?.serie?.objectId;

  const user = useSelector((state) => state.user.user);
  const isOwned = useSelector((state) => isAlbumOwned(state, albumObjectId));
  const albumInLibrary = useSelector((state) => state.profiles.myAlbums[albumObjectId]);
  const previousAlbumsFromSerie =
    useSelector((state) => getPreviousAlbumsFromSerie(state, { albumObjectId, serieObjectId })) ||
    [];
  const noPreviousAlbumsOwned = useSelector((state) =>
    isNoPreviousAlbumsOwned(state, { albumObjectId, serieObjectId }),
  );

  const handleCollectionClick = (e) => {
    e.preventDefault();
    const albumDefaultPrint = {
      album: { objectId: albumObjectId },
      objectId: album.defaultPrintObjectId,
    };
    const previousAlbumDefaultPrints = [album, ...previousAlbumsFromSerie].map((album) => ({
      album: { objectId: album.objectId },
      objectId: album.defaultPrintObjectId,
    }));
    const ownedAlbumPrints = Object.entries(albumInLibrary?.userPrints || {})
      .map((print) => {
        if (print.isInLibrary) {
          return {
            album: { objectId: albumObjectId },
            objectId: print.objectId,
          };
        } else return null;
      })
      .filter((row) => row);

    if (!isOwned) {
      if (
        noPreviousAlbumsOwned &&
        window.confirm('Voulez-vous ajouter tous les tomes précédents ?')
      ) {
        addPrintsToLibrary(user, previousAlbumDefaultPrints);
      } else {
        addPrintsToLibrary(user, [albumDefaultPrint]);
      }
    } else {
      removePrintsFromLibrary(
        user,
        ownedAlbumPrints.length ? ownedAlbumPrints : [albumDefaultPrint],
        true,
      );
    }
  };

  return (
    <div className="px-3">
      <ButtonWithIcon
        onClick={handleCollectionClick}
        icon={
          isOwned
            ? getAssetImage('icon_remove_collection.svg')
            : getAssetImage('icon_add_collection.svg')
        }
      >
        <span className={isOwned ? 'text-success' : ''}>{isOwned ? 'Supprimer' : 'Ajouter'}</span>
      </ButtonWithIcon>
    </div>
  );
};

HorizontalAddToCollectionZone.propTypes = {
  album: PropTypes.object,
  serie: PropTypes.object,
};

export default HorizontalAddToCollectionZone;
