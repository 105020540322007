import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { LOAD_ALBUMS_V2, loadAlbumsV2 } from 'bubble-reducers/src/reducers/albums';

import { buildSortedQueryString } from 'bubble-utils/src/string-utils';

import { getSeoForAvailableAlbums } from '@/services/seo-utils';

import AlbumCard from '@/components/AlbumCard/AlbumCard';
import BubbleCheckbox from '@/components/BubbleCheckbox/BubbleCheckbox';
import BubbleHelmet from '@/components/BubbleHelmet/BubbleHelmet';
import Pagination from '@/components/Pagination/Pagination';

const Agenda = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const loading = !!useSelector((state) => state.albums.loading[LOAD_ALBUMS_V2]);

  const page = searchParams.get('page') || 1;
  const perPage = searchParams.get('per-page') || 50;
  const available = searchParams.get('available') || 1;
  const categories = searchParams.get('categories') || '';

  const queryString = buildSortedQueryString({ page, 'per-page': perPage, available, categories });

  const albums = useSelector((state) => state.albums.albums);
  const paginatedAlbums =
    useSelector((state) => state.albums.albumObjectIdsMapByParams[queryString]) || {};

  const setCategory = (category) => {
    let categoriesAsArray = categories ? categories.split(',') : [];
    if (categoriesAsArray.includes(category)) {
      categoriesAsArray = categoriesAsArray.filter((c) => c !== category);
    } else {
      categoriesAsArray.push(category);
    }
    setSearchParams({ categories: categoriesAsArray.join(',') });
  };

  const albumsAvailable = (paginatedAlbums?.albums || [])
    .map((albumObjectId) => {
      return albums[albumObjectId];
    })
    .filter(Boolean)
    .sort((a, b) => {
      return a.publicationDate < b.publicationDate ? 1 : -1;
    });

  useEffect(() => {
    dispatch(loadAlbumsV2({ options: { available, page, 'per-page': perPage, categories } }));
  }, [page, perPage, categories, available]);

  return (
    <div className="bb-background-light-grey">
      {!!albums && <BubbleHelmet seo={getSeoForAvailableAlbums(albumsAvailable)} />}
      <div alt="placeholder" className="top-image-container">
        <div className="container">
          <div className="px-sm-3">
            <div className="row">
              <div className="col-12">
                <h1 className="d-flex justify-content-center flex-column py-5">
                  <div className="d-flex justify-content-center">
                    <span className="badge bg-bubble-color badge-padding bb-medium-text-size px-2 fw-normal">
                      Albums en stock
                    </span>
                  </div>
                  <div className="text-center bb-xxl-text-size fw-bold agenda-title-line-height pt-4">
                    BD, Comics et Mangas en stock
                  </div>
                </h1>
              </div>
            </div>

            <div className="row px-3 pb-5">
              <div className="offset-lg-2 col-lg-8 bg-white rounded bb-shadow-hover p-3">
                <div className="d-sm-flex align-items-center justify-content-evenly bb-medium-text-size">
                  <div className="me-5 py-2">
                    <BubbleCheckbox
                      label="Bande dessinées"
                      checked={categories?.includes('bd')}
                      onChange={() => setCategory('bd')}
                    />
                  </div>
                  <div className="me-5 py-2">
                    <BubbleCheckbox
                      label="Comics"
                      checked={categories?.includes('comics')}
                      onChange={() => setCategory('comics')}
                    />
                  </div>
                  <div className="me-5 py-2 ">
                    <BubbleCheckbox
                      label="Mangas"
                      checked={categories?.includes('mangas')}
                      onChange={() => setCategory('mangas')}
                    />
                  </div>
                  <div className="me-5 py-2 ">
                    <BubbleCheckbox
                      label="Jeunesse"
                      checked={categories?.includes('jeunesse')}
                      onChange={() => setCategory('jeunesse')}
                    />
                  </div>
                </div>
              </div>
            </div>

            {loading && !albumsAvailable?.length && (
              <div className="row">
                <div className="col-12">
                  <div className="d-flex flex-column align-items-center justify-content-center py-5">
                    <div className="spinner-grow text-bubble-color" role="status" />
                    <div className="text-bubble-color mt-2">
                      Vos albums arrivent{' '}
                      <span role="img" aria-label="hug">
                        🤗
                      </span>
                    </div>
                    <div className="my-5" />
                  </div>
                </div>
              </div>
            )}

            <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
              {albumsAvailable?.map((album) => (
                <AlbumCard
                  className="pb-3"
                  key={`agenda${album.objectId}`}
                  forcePublicationDate={true}
                  albumObjectId={album.objectId}
                />
              ))}
            </div>

            {!!albumsAvailable?.length && (
              <div className="row px-3 pb-5">
                <Pagination page={paginatedAlbums?.page} totalPages={paginatedAlbums?.totalPages} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Agenda;
