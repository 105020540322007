import React from 'react';

import { PROJECT } from '@/project-constants';
import { matchPath, useLocation } from 'react-router-dom';

import NArtFooter from '@/components/Footer/NArtFooter/NArtFooter';
import NArtHeader from '@/components/Header/NArtHeader/NArtHeader';

import BetaBanner from '../../components/BetaBanner/BetaBanner';
import CheckoutFooter from '../../components/Footer/CheckoutFooter';
import Footer from '../../components/Footer/Footer';
import CheckoutHeader from '../../components/Header/CheckoutHeader';
import Header from '../../components/Header/Header';

// import AdFullBackgournd from '@/components/AdFullBackrgound/AdFullBackrgound';
// import AdMobile from '@/components/AdMobile/AdMobile';
import './Layout.scss';

const Layout = (props) => {
  const location = useLocation();
  const loc = location.pathname.split('/');
  const token = loc[1];

  if (token === '9emeart') {
    let match;
    match = matchPath({ path: '/9emeart/:nArtCategory/:nArtSection/*' }, location.pathname);
    if (!match) {
      match = matchPath({ path: '/9emeart/:urlFirstParam' }, location.pathname);
      if (match) {
        const urlFirstParam = match?.params?.urlFirstParam;
        match.params.nArtCategory = PROJECT.PAPER_CATEGORIES.includes(urlFirstParam)
          ? urlFirstParam
          : null;
        match.params.nArtSection = PROJECT.PAPER_SECTIONS_NAMES.includes(urlFirstParam)
          ? urlFirstParam
          : null;
      }
    }
    return (
      <div
        className={`nart-category-color-${
          match?.params?.nArtCategory || 'all'
        } paper-body nart-background`}
      >
        <NArtHeader />
        <div className="d-flex align-items-center justify-content-center mt-xl-4">
          {/* <AdFullBackgournd /> */}
          <div className="nart-container">{props.children}</div>
        </div>
        <NArtFooter />
        {/* <AdMobile /> */}
      </div>
    );
  } else
    return (
      <>
        <BetaBanner />
        {token === 'checkout' ? <CheckoutHeader /> : <Header />}
        {props.children}
        {token === 'checkout' ? <CheckoutFooter /> : <Footer />}
      </>
    );
};

export default Layout;
