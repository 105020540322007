import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import './Pagination.scss';

const Pagination = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const page = Number(props.page || 1) || 1;

  const path = location.pathname;
  const urlParams = new URLSearchParams(location.search);
  const currentPage = Number(urlParams.get('page')) || 1;

  const isNearStart = page <= 2;
  const isNearEnd = page >= props.totalPages - 1;

  let pagesArray = [];
  if (props.totalPages < 5) {
    pagesArray = [...Array(props.totalPages)].map((v, index) => index + 1);
  } else if (isNearStart) {
    pagesArray = [1, 2, 3, '...', props.totalPages];
  } else if (isNearEnd) {
    pagesArray = [1, '...', props.totalPages - 2, props.totalPages - 1, props.totalPages];
  } else {
    pagesArray = [
      1,
      page - 1 === 2 ? null : '...',
      page - 1,
      page,
      page + 1,
      page + 1 === props.totalPages - 1 ? null : '...',
      props.totalPages,
    ];
  }
  pagesArray = pagesArray.filter((row) => row);

  const getPrevPath = () => {
    const page = currentPage - 1;
    urlParams.set('page', page);
    if (!props.callback) {
      navigate(`${path}?${urlParams.toString()}`);
    } else {
      return props.callback(page);
    }
    scrollToSection();
  };
  const getNextPath = () => {
    const page = currentPage + 1;
    urlParams.set('page', page);
    if (!props.callback) {
      navigate(`${path}?${urlParams.toString()}`);
    } else {
      return props.callback(page);
    }
    scrollToSection();
  };

  const getPathForPage = (page) => () => {
    urlParams.set('page', page);
    if (!props.callback) {
      navigate(`${path}?${urlParams.toString()}`);
    } else {
      props.callback(page);
    }
    scrollToSection();
  };

  const anchorOffset = (props.anchor || {}).offsetTop;

  const scrollToSection = useCallback(() => {
    window.scrollTo(0, anchorOffset);
  }, [anchorOffset]);

  return (
    <div className="row py-4">
      <div className="col-4">
        <button
          onClick={getPrevPath}
          className={`btn btn-link px-0 text-primary ${
            page <= 1 ? 'no-decoration text-secondary bb-link-no-event' : ''
          }`}
        >
          Précédente
        </button>
      </div>
      <div className="col-4 d-flex justify-content-center align-items-center">
        <div className="d-none d-md-block h-separator me-2 w-25 reverse" />
        {pagesArray.map((data, index) => {
          return (
            <React.Fragment key={'page_' + index + data}>
              {data === '...' ? (
                '...'
              ) : (
                <button
                  onClick={getPathForPage(data)}
                  className={`btn btn-link px-2 text-primary ${
                    data === page ? 'bb-link-no-event no-decoration text-secondary' : ''
                  }`}
                >
                  {data}
                </button>
              )}
            </React.Fragment>
          );
        })}
        <div className="d-none d-md-block h-separator ms-2 w-25" />
      </div>
      <div className="col-4 d-flex justify-content-end">
        <button
          onClick={getNextPath}
          className={`btn btn-link px-0 text-primary ${
            page >= props.totalPages ? 'no-decoration text-secondary bb-link-no-event' : ''
          }`}
        >
          Suivante
        </button>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  totalPages: PropTypes.number,
  page: PropTypes.number,
  anchor: PropTypes.object,
  callback: PropTypes.func,
};

export default Pagination;
