import PropTypes from 'prop-types';
import React from 'react';

const SerieTerminatedBadge = (props) => (
  <span
    className={`d-flex align-items-center text-black badge bb-bordered ${
      props.smallPadding ? 'py-0' : ''
    }`}
  >
    {props.isTerminated ? 'Terminée' : 'En cours'}
  </span>
);

SerieTerminatedBadge.propTypes = {
  isTerminated: PropTypes.bool,
  smallPadding: PropTypes.bool,
};

export default SerieTerminatedBadge;
