import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { makeGetSerieAlbums } from 'bubble-reducers/src/selectors';

import Cover from '@/components/Cover/Cover';
import SerieFollowBadge from '@/components/SerieFollowBadge/SerieFollowBadge';
import SerieTerminatedBadge from '@/components/SerieTerminatedBadge/SerieTerminatedBadge';
import StarLine from '@/components/StarLine/StarLine';

import './SerieBigCard.css';

/**
 * Renders a serie card
 */

const SerieBigCard = ({ serieObjectId, grey }) => {
  const getSerieAlbumsMemoized = useMemo(() => makeGetSerieAlbums(serieObjectId), [serieObjectId]);
  const serie = useSelector((state) => state.series.series[serieObjectId]) || {};
  const albums = useSelector((state) => getSerieAlbumsMemoized(state, serieObjectId));

  const renderOtherAlbums = (serie, albums) => {
    let newAlbums = albums.slice().filter((row) => row);
    const hasMoreThanThreshold = newAlbums.length > 4;
    if (hasMoreThanThreshold) {
      newAlbums.splice(1, albums.length - 4);
    }

    return (
      <>
        <div>
          <div className="fw-bold">Découvrez les albums de {serie.title} :</div>
          {newAlbums.map((album, index) => (
            <React.Fragment key={`big-${serie.objectId}-${album.objectId}`}>
              <Link
                className="d-block text-truncate"
                to={`/${album.permalink}/album/${album.objectId}`}
              >
                {album.tome !== null ? 'Tome ' + album.tome + ' - ' : ''}
                {album.title || serie.title}
              </Link>
              {index === 0 && hasMoreThanThreshold ? <>...</> : null}
            </React.Fragment>
          ))}
        </div>
        <Link className="text-primary" to={`/${serie.permalink}/serie/${serie.objectId}`}>
          Voir tous les albums
        </Link>
      </>
    );
  };

  const images = (serie.images || {}).front || {};

  return (
    <div
      className={`p-3 h-100 w-100 rounded position-relative ${grey ? 'bb-background-light-grey' : 'bg-white'}`}
    >
      <SerieFollowBadge
        noflex
        className="position-absolute big-card-follow"
        serieObjectId={serie.objectId}
      />
      <div className="d-flex align-items-center mb-3">
        <Link className="me-3 no-decoration" to={`/${serie.permalink}/serie/${serie.objectId}`}>
          <Cover
            alt={`Couverture du premier album de la série ${serie.title}`}
            rounded
            shadow
            height={160}
            imageUrl={images.smallFramed}
          />
        </Link>
        <div>
          <div className="d-flex mb-2">
            <SerieTerminatedBadge isTerminated={serie.isTerminated} />
          </div>
          <div className="fw-bold">{serie.title}</div>
          <StarLine note={serie.note} />
        </div>
      </div>
      <p className="overflow-hidden-2-lines">{serie.descriptionShort}</p>
      {renderOtherAlbums(serie, albums)}
    </div>
  );
};

export default SerieBigCard;
