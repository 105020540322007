import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { extractAlbumAndPrints } from 'bubble-utils/src/album-utils';
import { buildSortedQueryString } from 'bubble-utils/src/string-utils';

import {
  loadAlbumApi,
  loadAlbumSerieApi,
  loadAlbumsApi,
  loadAlbumsV2Api,
  updateAlbumTagsApi,
} from '../services/api';

import { storePrints } from '../reducers/prints';
import { storeSeries } from '../reducers/series';
import { loadUserTags } from '../reducers/tags';
import { getClientToken } from '../selectors';

const LOAD_ALBUM = 'albums/loadAlbum';
const LOAD_ALBUM_SERIE = 'albums/loadAlbumSerie';
export const LOAD_ALBUMS = 'albums/loadAlbums';
export const LOAD_ALBUMS_V2 = 'albums/loadAlbumsV2';
export const LOAD_XMAS_SELECTION = 'albums/loadXmasSelection';
const UPDATE_TAGS_ON_ALBUM = 'albums/updateAlbumTags';

const initialState = {
  mapOfAlbumIdsLastPublished: {}, // TODO: unnecessary, use albumObjectIdsMapByParams
  mapOfAlbumIdsBestSellers: {}, // TODO: unnecessary, use albumObjectIdsMapByParams
  albumObjectIdsMapByParams: {},
  albums: {},
  // this is necessary because some routes don't send all the prints, thus erasing the ones in the 'albums' map
  albumPrintsObjectIds: {},
  status: null,

  // xmas selection tmp
  xmasSelectionAlbumObjectIdsMapByParams: {},

  loading: {},
  errors: {},
};

export const loadAlbum = createAsyncThunk(LOAD_ALBUM, async (params, { getState, dispatch }) => {
  const albumObjectId = params?.albumObjectId || null;

  const clientToken = getClientToken(getState());
  const album = await loadAlbumApi(clientToken, albumObjectId);

  const { albums, prints } = extractAlbumAndPrints([album]);
  dispatch(storeAlbums({ albums }));
  dispatch(storePrints({ prints }));
});

export const loadAlbumSerie = createAsyncThunk(
  LOAD_ALBUM_SERIE,
  async (params, { getState, dispatch }) => {
    const albumObjectId = params?.albumObjectId || null;

    const clientToken = getClientToken(getState());
    const serie = await loadAlbumSerieApi(clientToken, albumObjectId);
    dispatch(storeSeries({ series: [serie] }));
  },
);

export const loadAlbums = createAsyncThunk(LOAD_ALBUMS, async (params, { getState, dispatch }) => {
  const options = params?.options || null;

  const clientToken = getClientToken(getState());
  const albumsResponse = await loadAlbumsApi(clientToken, options);

  const { albums, prints } = extractAlbumAndPrints(albumsResponse);
  dispatch(storeAlbums({ albums }));
  dispatch(storePrints({ prints }));

  return { albums: albumsResponse, options };
});

export const loadAlbumsV2 = createAsyncThunk(
  LOAD_ALBUMS_V2,
  async (params, { getState, dispatch }) => {
    const options = params?.options || null;

    const clientToken = getClientToken(getState());
    const paginatedAlbums = await loadAlbumsV2Api(clientToken, options);

    const { albums, prints } = extractAlbumAndPrints(paginatedAlbums.data);
    dispatch(storeAlbums({ albums }));
    dispatch(storePrints({ prints }));

    return { paginatedAlbums, options };
  },
);

export const loadXmasSelection = createAsyncThunk(
  LOAD_XMAS_SELECTION,
  async (params, { getState, dispatch }) => {
    const options = params?.options || null;

    const clientToken = getClientToken(getState());
    const albumsResponse = await loadAlbumsApi(clientToken, options);

    const { albums, prints } = extractAlbumAndPrints(albumsResponse);
    dispatch(storeAlbums({ albums }));
    dispatch(storePrints({ prints }));

    return { albums: albumsResponse, options };
  },
);

export const updateAlbumTags = createAsyncThunk(
  UPDATE_TAGS_ON_ALBUM,
  async (params, { getState, dispatch }) => {
    const userObjectId = params?.userObjectId || null;
    const albumObjectId = params?.albumObjectId || null;
    const tags = params?.tags || null;
    const forcedWeight = params?.forcedWeight || null;

    const clientToken = getClientToken(getState());

    const album = await updateAlbumTagsApi(
      clientToken,
      userObjectId,
      albumObjectId,
      tags,
      forcedWeight,
    );

    const { albums, prints } = extractAlbumAndPrints([album]);
    dispatch(storeAlbums({ albums }));
    dispatch(storePrints({ prints }));

    dispatch(loadUserTags({ userObjectId }));
  },
);

const albumsSlice = createSlice({
  name: 'albums',
  initialState,
  reducers: {
    resetAlbumReducer: {
      reducer: (state, action) => initialState,
    },
    storeAlbums: {
      reducer: (state, action) => {
        const albumPrintsObjectIds = { ...state.albumPrintsObjectIds };
        const albums = (action.payload.albums || []).reduce(
          (map, album) => {
            map[album.objectId] = album;
            albumPrintsObjectIds[album.objectId] = new Set(
              albumPrintsObjectIds[album.objectId] || [],
            );
            album.prints.forEach((print) => albumPrintsObjectIds[album.objectId].add(print));
            albumPrintsObjectIds[album.objectId] = Array.from(albumPrintsObjectIds[album.objectId]);
            album.prints = albumPrintsObjectIds[album.objectId];
            return map;
          },
          { ...state.albums },
        );
        state.albums = albums;
        state.albumPrintsObjectIds = albumPrintsObjectIds;
      },
    },
  },
  extraReducers: (builder) => {
    builder
      // loadAlbum
      .addCase(loadAlbum.pending, (state, action) => {
        state.loading[LOAD_ALBUM] = true;
        state.errors[LOAD_ALBUM] = null;
      })
      .addCase(loadAlbum.fulfilled, (state, action) => {
        state.loading[LOAD_ALBUM] = false;
        state.errors[LOAD_ALBUM] = null;
      })
      .addCase(loadAlbum.rejected, (state, action) => {
        state.loading[LOAD_ALBUM] = false;
        state.errors[LOAD_ALBUM] = action.error;
      })
      // loadAlbumSerie
      .addCase(loadAlbumSerie.pending, (state, action) => {
        state.loading[LOAD_ALBUM_SERIE] = true;
        state.errors[LOAD_ALBUM_SERIE] = null;
      })
      .addCase(loadAlbumSerie.fulfilled, (state, action) => {
        state.loading[LOAD_ALBUM_SERIE] = false;
        state.errors[LOAD_ALBUM_SERIE] = null;
      })
      .addCase(loadAlbumSerie.rejected, (state, action) => {
        state.loading[LOAD_ALBUM_SERIE] = false;
        state.errors[LOAD_ALBUM_SERIE] = action.error;
      })
      // loadAlbums
      .addCase(loadAlbums.pending, (state, action) => {
        state.loading[LOAD_ALBUMS] = true;
        state.errors[LOAD_ALBUMS] = null;
      })
      .addCase(loadAlbums.fulfilled, (state, action) => {
        const options = action.payload.options;
        const queryString = buildSortedQueryString(options);
        const albumObjectIds = action.payload.albums.map((album) => album.objectId);

        let mapOfAlbumIdsLastPublished = state.mapOfAlbumIdsLastPublished;
        let mapOfAlbumIdsBestSellers = state.mapOfAlbumIdsBestSellers;
        const key =
          (options.category || '') + (options.genre || '') + (options.tags || '') || 'all';
        if (options?.last_published) {
          mapOfAlbumIdsLastPublished = Object.assign({}, mapOfAlbumIdsLastPublished);
          mapOfAlbumIdsLastPublished[key] = albumObjectIds;
        }
        if (options?.best_sellers) {
          mapOfAlbumIdsBestSellers = Object.assign({}, mapOfAlbumIdsBestSellers);
          mapOfAlbumIdsBestSellers[key] = albumObjectIds;
        }

        const albumObjectIdsMapByParams = { ...state.albumObjectIdsMapByParams };
        // as of 19/05/2022: albums list route is not paginated, fake it
        albumObjectIdsMapByParams[queryString] = {
          total: albumObjectIds.length,
          perPage: albumObjectIds.length,
          totalPages: 1,
          page: 1,
          query: queryString,
          albums: albumObjectIds,
        };

        state.mapOfAlbumIdsLastPublished = mapOfAlbumIdsLastPublished;
        state.mapOfAlbumIdsBestSellers = mapOfAlbumIdsBestSellers;
        state.albumObjectIdsMapByParams = albumObjectIdsMapByParams;

        state.loading[LOAD_ALBUMS] = false;
        state.errors[LOAD_ALBUMS] = null;
      })
      .addCase(loadAlbums.rejected, (state, action) => {
        state.loading[LOAD_ALBUMS] = false;
        state.errors[LOAD_ALBUMS] = action.error;
      })
      // loadAlbumsV2
      .addCase(loadAlbumsV2.pending, (state, action) => {
        state.loading[LOAD_ALBUMS_V2] = true;
        state.errors[LOAD_ALBUMS_V2] = null;
      })
      .addCase(loadAlbumsV2.fulfilled, (state, action) => {
        const { paginatedAlbums, options } = action.payload;
        const queryString = buildSortedQueryString(options);

        state.albumObjectIdsMapByParams[queryString] = {
          total: paginatedAlbums.total,
          perPage: paginatedAlbums.perPage,
          totalPages: paginatedAlbums.totalPages,
          page: paginatedAlbums.page,
          query: queryString,
          albums: paginatedAlbums.data.map((album) => album.objectId),
        };

        state.loading[LOAD_ALBUMS_V2] = false;
        state.errors[LOAD_ALBUMS_V2] = null;
      })
      .addCase(loadAlbumsV2.rejected, (state, action) => {
        state.loading[LOAD_ALBUMS_V2] = false;
        state.errors[LOAD_ALBUMS_V2] = action.error;
      })
      // loadXmasSelection
      .addCase(loadXmasSelection.pending, (state, action) => {
        state.loading[LOAD_XMAS_SELECTION] = true;
        state.errors[LOAD_XMAS_SELECTION] = null;
      })
      .addCase(loadXmasSelection.fulfilled, (state, action) => {
        state.xmasSelectionAlbumObjectIdsMapByParams = {
          ...state.xmasSelectionAlbumObjectIdsMapByParams,
          [buildSortedQueryString(action.payload.options)]: action.payload.albums.map(
            (album) => album.objectId,
          ),
        };

        state.loading[LOAD_XMAS_SELECTION] = false;
        state.errors[LOAD_XMAS_SELECTION] = null;
      })
      .addCase(loadXmasSelection.rejected, (state, action) => {
        state.loading[LOAD_XMAS_SELECTION] = false;
        state.errors[LOAD_XMAS_SELECTION] = action.error;
      })
      // updateAlbumTags
      .addCase(updateAlbumTags.pending, (state, action) => {
        state.loading[UPDATE_TAGS_ON_ALBUM] = true;
        state.errors[UPDATE_TAGS_ON_ALBUM] = null;
      })
      .addCase(updateAlbumTags.fulfilled, (state, action) => {
        state.loading[UPDATE_TAGS_ON_ALBUM] = false;
        state.errors[UPDATE_TAGS_ON_ALBUM] = null;
      })
      .addCase(updateAlbumTags.rejected, (state, action) => {
        state.loading[UPDATE_TAGS_ON_ALBUM] = false;
        state.errors[UPDATE_TAGS_ON_ALBUM] = action.error;
      });
  },
});

export default albumsSlice.reducer;

export const { storeAlbums, resetAlbumReducer } = albumsSlice.actions;
