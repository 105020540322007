import React, { useCallback, useState } from 'react';

import { Link } from 'react-router-dom';

import { Icon } from '@/components/Icon/Icon';
import WithClickHandler from '@/components/WithClickHandler/WithClickHandler';

import './BetaBanner.scss';

const BetaBanner = () => {
  const [open, _setOpen] = useState(true);

  const close = useCallback(() => _setOpen(false), [_setOpen]);

  return (
    <>
      {open && (
        <div className="beta-banner text-white text-center fw-bold bg-primary">
          <span role="img" aria-label="Christmas tree" className="">
            ⚠️
          </span>{' '}
          Afin de garantir la livraison de vos commandes avant Noël, seuls les livres actuellement
          en stock sont disponibles à l'achat.
          <br />
          Les livres en réassort “Expédiables sous XX jours” sont temporairement suspendus.{' '}
          <Link to="/en-stock">Cliquez ici pour voir nos albums en stock</Link>
          <br />
          Nous vous remercions de votre compréhension et restons à votre disposition pour toute
          question.
        </div>
      )}
    </>
  );
};

export default BetaBanner;
